import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import "antd/dist/antd.css";
import "./index.css";

const config = {
  apiKey: "AIzaSyCJ_Osmj69uY1pkmcE1ILgW0xUI-A08bco",
  authDomain: "helitaxii.firebaseapp.com",
  databaseURL: "https://helitaxii.firebaseio.com",
  projectId: "helitaxii",
  storageBucket: "helitaxii.appspot.com",
  messagingSenderId: "423020942585",
};

// const config = {
//   apiKey: "AIzaSyBsO3j9hCtkTFnFYJFAwWpqoUzocfdvMow",
//   authDomain: "helitaxii-dev.firebaseapp.com",
//   projectId: "helitaxii-dev",
//   storageBucket: "helitaxii-dev.appspot.com",
//   messagingSenderId: "842170867801",
//   appId: "1:842170867801:web:45ac4ce07fa51502335148",
// };

initializeApp(config);

const theme = createMuiTheme({
  palette: {
    primary: { light: indigo[300], main: "#015299", dark: indigo[700] },
  },
  typography: {
    useNextVariants: true,
  },
});

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
