import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { useContext, useState } from "react";
import api from "../../api";
import { Button, Input, InputNumber, DatePicker } from "antd";
import { context } from "../../providers/user.provider";
import isNumber from "lodash/isNumber";

const AddScheduleModal = ({ onClose }) => {
  const [schedule, setSchedule] = useState({
    startDate: "",
    endDate: "",
    name: "",
    defaultSeats: 6,
    pricePerSeat: 0,
    payableAdvancePerSeat: 0,
  });
  const userContext = useContext(context);

  const onAdd = () => {
    if (
      !schedule.name ||
      !schedule.startDate ||
      !schedule.endDate ||
      !schedule.defaultSeats ||
      !isNumber(schedule.pricePerSeat) ||
      !isNumber(schedule.payableAdvancePerSeat)
    ) {
      userContext.showError("Please enter all details.");
      return;
    }

    if (schedule.startDate > schedule.endDate) {
      userContext.showError("End date should be greater than start date.");
      return;
    }

    userContext.showLoading();

    api
      .createSchedule({
        name: schedule.name,
        startDate: schedule.startDate.format("MM/DD/YYYY"),
        endDate: schedule.endDate.format("MM/DD/YYYY"),
        defaultSeats: schedule.defaultSeats,
        pricePerSeat: schedule.pricePerSeat,
        payableAdvancePerSeat: schedule.payableAdvancePerSeat,
      })
      .then(() => {
        userContext.showSuccess("Package created successfully");
        onClose();
      })
      .catch(() => {
        userContext.showError("Error creating package");
      })
      .finally(() => {
        userContext.hideLoading();
      });
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      style={{
        zIndex: 1029,
      }}
    >
      <DialogTitle>Add new Package</DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <h4>Name</h4>
        <Input
          value={schedule.name}
          type="text"
          onChange={(e) => setSchedule({ ...schedule, name: e.target.value })}
        />

        <h4 style={{ marginTop: "5px" }}>Start Date</h4>
        <DatePicker
          format={"DD MMM YYYY"}
          value={schedule.startDate}
          onChange={(date) => setSchedule({ ...schedule, startDate: date })}
          disabledDate={(current) => {
            return current && current < moment().endOf("day");
          }}
        />

        <h4 style={{ marginTop: "5px" }}>End Date</h4>
        <DatePicker
          format={"DD MMM YYYY"}
          value={schedule.endDate}
          onChange={(date) => setSchedule({ ...schedule, endDate: date })}
          disabledDate={(current) => {
            if (schedule.startDate) {
              return (
                current && current < moment(schedule.startDate).endOf("day")
              );
            }
            return current && current < moment().endOf("day");
          }}
        />

        <h4 style={{ marginTop: "5px" }}>Default Seats for Thumby aviation</h4>
        <InputNumber
          value={schedule.defaultSeats}
          onChange={(value) =>
            setSchedule({ ...schedule, defaultSeats: value })
          }
        />

        <h4 style={{ marginTop: "5px" }}>Price per seat</h4>
        <InputNumber
          value={schedule.pricePerSeat}
          onChange={(value) =>
            setSchedule({ ...schedule, pricePerSeat: value })
          }
        />

        <h4 style={{ marginTop: "5px" }}>Payable advance per seat</h4>
        <InputNumber
          value={schedule.payableAdvancePerSeat}
          onChange={(value) =>
            setSchedule({ ...schedule, payableAdvancePerSeat: value })
          }
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            type="secondary"
            onClick={onCancel}
            style={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={onAdd}>
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddScheduleModal;
