import React, { Component } from "react";
import Api from "../../api";
import RequestsTable from "./RequestsTable";

export default class OthersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exclusiveCharters: [],
      pageNumber: 1,
      pageSize: 20,
      totalCount: 0,
      status: "",
      loading: false,
      selectedExclusiveCharter: null,
      type: "Chardham",
      source: "",
    };
  }
  componentDidMount() {
    this.getCharterRequests();
  }

  onCommentsView = (selectedExclusiveCharter) => {
    this.setState({
      selectedExclusiveCharter,
    });
  };

  getCharterRequests = () => {
    const { pageNumber, pageSize, status, type, source } = this.state;

    this.setState({ loading: true });
    Api.getExclusiveCharterRequests({
      status,
      pageNumber,
      pageSize,
      type,
      source,
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({
          exclusiveCharters: res.exclusiveCharters,
          totalCount: res.totalCount,
        })
      )
      .catch((err) => console.log(err))
      .finally(() => this.setState({ loading: false }));
  };

  goToPreviousPage = () => {
    const { pageNumber } = this.state;

    this.setState(
      {
        pageNumber: pageNumber - 1,
      },
      this.getCharterRequests
    );
  };

  goToNextPage = () => {
    const { pageNumber } = this.state;

    this.setState(
      {
        pageNumber: pageNumber + 1,
      },
      this.getCharterRequests
    );
  };

  onSelectStatus = (option) => {
    this.setState({ status: option.value, pageNumber: 1, pageSize: 20 }, () =>
      this.getCharterRequests()
    );
  };

  onSelectType = (option) => {
    this.setState(
      { type: option.value, pageNumber: 1, pageSize: 20, totalCount: 0 },
      () => this.getCharterRequests()
    );
  };

  onSelectSource = (option) => {
    this.setState(
      { source: option.value, pageNumber: 1, pageSize: 20, totalCount: 0 },
      () => this.getCharterRequests()
    );
  };

  onClearStatus = () => {
    this.setState({ status: "" }, () => this.getCharterRequests());
  };

  onChangeStatus = (id, status) => {
    Api.changeExclusiveCharterRequestStatus(id, status)
      .then((res) => res.json())
      .then(() => this.getCharterRequests())
      .catch((err) => console.log(err));
  };

  onAddComment = (id, comment = "") => {
    if (!comment.trim()) return;

    Api.addExclusiveCharterComment(id, comment)
      .then((res) => res.json())
      .then(() => {
        this.getCharterRequests();
        this.setState({
          selectedExclusiveCharter: {
            ...this.state.selectedExclusiveCharter,
            comments: [
              ...(this.state.selectedExclusiveCharter.comments || []),
              comment,
            ],
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { exclusiveCharters, totalCount, pageSize, pageNumber } = this.state;

    return (
      <>
        <RequestsTable
          exclusiveCharters={exclusiveCharters}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={totalCount}
          onSelectStatus={this.onSelectStatus}
          onClearStatus={this.onClearStatus}
          onClickNext={this.goToNextPage.bind(this)}
          onClickPrev={this.goToPreviousPage.bind(this)}
          loading={this.state.loading}
          onChangeStatus={this.onChangeStatus.bind(this)}
          onCommentsView={this.onCommentsView.bind(this)}
          selectedExclusiveCharter={this.state.selectedExclusiveCharter}
          onAddComment={this.onAddComment.bind(this)}
          onClose={() => this.setState({ selectedExclusiveCharter: null })}
          onSelectType={this.onSelectType.bind(this)}
          sources={this.props.sources}
          onSelectSource={this.onSelectSource.bind(this)}
          source={this.state.source}
          type={this.state.type}
          status={this.state.status}
        />
      </>
    );
  }
}
