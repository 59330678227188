const BookingSummary = ({ booking }) => {
  return (
    <div>
      <p>Booking ID: {booking._id}</p>
      <p>Lead Passenger name: {booking.name}</p>
      <p>Lead Passenger email: {booking.email}</p>
      <p>Lead Passenger mobile number: {booking.mobileNumber}</p>
      <p>Booking status: {getFormattedStatus(booking.status)}</p>
      <p>Total cost: {booking.totalCost}</p>
      <p>Total seats: {booking.seats}</p>
    </div>
  );
};

const getFormattedStatus = (status) => {
  return status.charAt(0).toUpperCase() + status.slice(1);
};

export default BookingSummary;
