import React, { useState, useEffect } from "react";
import { useRole } from "../../utils/useRole";

import RequestTab from "./RequestTab";
import api from "../../api";
import { Redirect } from "react-router-dom";

const ExclusiveCharterPage = () => {
  const [sources, setSources] = useState([]);
  const role = useRole();

  useEffect(() => {
    api.getSources().then((res) => {
      setSources(res.sources);
    });
  }, []);

  if (role !== "admin") {
    return <Redirect to="/charters" />;
  }

  return (
    <div>
      <h2>Callback Requests</h2>

      <RequestTab sources={sources} />
    </div>
  );
};

export default ExclusiveCharterPage;
