import { useCallback, useContext, useEffect, useState } from "react";
import { useRole } from "../../utils/useRole";
import { useHistory } from "react-router-dom";
import api from "../../api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Badge, Input, Select, DatePicker, Divider } from "antd";
import debounce from "lodash/debounce";
import { context } from "../../providers/user.provider";
import {
  MessageOutlined,
  TeamOutlined,
  MailOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import CommentsModal from "./CommentsModal";
import PassengersModal from "./PassengersModal";
import PaginationControls from "../../components/PaginationControls";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StatusChangeModal from "./StatusChangeModal";
import UpdatePaymentModal from "./UpdatePaymentModal";

const { Option } = Select;

const getAllowedStatuses = (currentStatus) => {
  switch (currentStatus) {
    case "new":
      return ["new", "lead", "closed"];
    case "lead":
      return ["lead", "prospect", "closed"];
    case "prospect":
      return ["prospect", "client", "closed"];
    case "client":
      return ["client", "closed"];
    case "closed":
      return ["lead", "closed"];
    default:
      return [];
  }
};

const LeadsPage = () => {
  const role = useRole();
  const history = useHistory();
  const [leads, setLeads] = useState({ entities: [], totalCount: 0 });
  const [schedules, setSchedules] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortColumn: "createdAt",
    sortDirection: "desc",
    agentId: "",
    statuses: [],
    scheduleId: "",
    travelDate: "",
    search: "",
  });
  const [selectedLeadForComments, setSelectedLeadForComments] = useState(null);
  const [selectedLeadForPassengers, setSelectedLeadForPassengers] =
    useState(null);
  const [selectedLeadForResendEmail, setSelectedLeadForResendEmail] =
    useState(null);
  const [selectedLeadForStatusChange, setSelectedLeadForStatusChange] =
    useState({
      booking: null,
      newStatus: "",
    });
  const [selectedLeadForPaymentUpdate, setSelectedLeadForPaymentUpdate] =
    useState(null);
  const userContext = useContext(context);

  const fetchSchedules = useCallback(() => {
    api.getCharterSchedules().then((response) => {
      setSchedules(response.charterSchedules);
    });
  }, []);

  const fetchLeads = useCallback(() => {
    // Fetch leads here
    userContext.showLoading();
    api
      .getCharterBookings({
        ...filter,
        travelDate: filter.travelDate
          ? filter.travelDate.format("MM/DD/YYYY")
          : "",
      })
      .then((response) => {
        setLeads({
          entities: response.bookings.filter((lead) => {
            return lead.agentId.isThumbyAgent;
          }),
          totalCount: response.totalCount,
        });
      })
      .finally(() => {
        userContext.hideLoading();
      });
  }, [filter]);

  const onAddComment = (leadId, comment) => {
    api.addLeadComment({ leadId, comment }).then(() => {
      fetchLeads();
      setSelectedLeadForComments((prev) => ({
        ...prev,
        comments: [...(prev.comments || []), comment],
      }));
    });
  };

  useEffect(() => {
    if (!role) return;

    if (role !== "admin") {
      history.replace("/charters/bookings");
    }

    fetchLeads();
  }, [role, history, fetchLeads]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  const debouncedChangeHandler = useCallback(
    debounce((newValue) => {
      setFilter((prev) => ({
        ...prev,
        search: newValue,
      }));
    }, 500),
    []
  );

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearch(newValue);
    debouncedChangeHandler(newValue);
  };

  const onChangeStatus = (booking, status) => {
    console.log(booking);
    setSelectedLeadForStatusChange({
      booking,
      newStatus: status,
    });
  };

  const onResendEmail = async () => {
    try {
      await api.resendEmail(selectedLeadForResendEmail._id);
      userContext.showSuccess("Email sent successfully");
      setSelectedLeadForResendEmail(null);
    } catch (e) {
      userContext.showError("Couldn't send email");
    }
  };

  return (
    <div>
      {selectedLeadForPaymentUpdate && (
        <UpdatePaymentModal
          onClose={(newBooking) => {
            if (newBooking?._id) {
              setLeads((prev) => ({
                ...prev,
                entities: prev.entities.map((booking) => {
                  if (booking?._id === newBooking._id) {
                    return {
                      ...booking,
                      amountPaid: newBooking.amountPaid,
                      totalCost: newBooking.totalCost,
                      transactions: newBooking.transactions,
                    };
                  }

                  return booking;
                }),
              }));
            }

            setSelectedLeadForPaymentUpdate(null);
          }}
          booking={selectedLeadForPaymentUpdate}
        />
      )}
      {selectedLeadForStatusChange.booking && (
        <StatusChangeModal
          booking={selectedLeadForStatusChange.booking}
          newStatus={selectedLeadForStatusChange.newStatus}
          onClose={(newBooking) => {
            if (newBooking?._id) {
              setLeads((prev) => ({
                ...prev,
                entities: prev.entities.map((booking) => {
                  if (booking._id === newBooking._id) {
                    return {
                      ...booking,
                      status: newBooking.status,
                      amountPaid: newBooking.amountPaid,
                      totalCost: newBooking.totalCost,
                      transactionIds: newBooking.transactionIds,
                      accountManager: newBooking.accountManager,
                    };
                  }

                  return booking;
                }),
              }));
            }

            setSelectedLeadForStatusChange({
              booking: null,
              newStatus: "",
            });
          }}
        />
      )}
      {selectedLeadForResendEmail && (
        <Dialog
          open
          onClose={() => setSelectedLeadForResendEmail(null)}
          style={{
            zIndex: 1029,
          }}
        >
          <DialogTitle>Resend email</DialogTitle>
          <DialogContent>
            <div>
              <p>
                Are you sure you want to resend the{" "}
                {selectedLeadForResendEmail.status === "prospect" ? (
                  <b>Quotation email </b>
                ) : selectedLeadForResendEmail.status === "client" ? (
                  selectedLeadForResendEmail.passengers?.length ===
                  selectedLeadForResendEmail.seats ? (
                    <b>Ticket </b>
                  ) : (
                    <b>PassengerDetails form email </b>
                  )
                ) : (
                  "email"
                )}
                to the customer?
              </p>
              <p>
                <strong>Email:</strong> {selectedLeadForResendEmail.email}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
              >
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => setSelectedLeadForResendEmail(null)}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={onResendEmail}>
                  Send email
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <h1>Package Leads</h1>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <h4>Search</h4>
          <Input
            value={search}
            style={{ width: "300px" }}
            onChange={handleChange}
            placeholder="Search by Booking ID,  Name, Email, Phone number"
            allowClear
            onClear={() => {
              setFilter((prev) => ({
                ...prev,
                search: "",
              }));
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "10px",
          }}
        >
          <h4>Status</h4>
          <Select
            allowClear
            mode="multiple"
            value={filter.statuses}
            style={{ width: "200px" }}
            onClear={() => {
              setFilter((prev) => ({
                ...prev,
                statuses: [],
              }));
            }}
            onChange={(value) => {
              setFilter((prev) => ({
                ...prev,
                statuses: value,
              }));
            }}
          >
            <Option value="new">New</Option>
            <Option value="lead">Lead</Option>
            <Option value="prospect">Prospect</Option>
            <Option value="client">Client</Option>
            <Option value="closed">Closed</Option>
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "10px",
          }}
        >
          <h4>Schedule</h4>
          <Select
            allowClear
            value={filter.scheduleId}
            style={{ width: "200px" }}
            onClear={() => {
              setFilter((prev) => ({
                ...prev,
                scheduleId: "",
              }));
            }}
            onChange={(value) => {
              setFilter((prev) => ({
                ...prev,
                scheduleId: value,
              }));
            }}
          >
            {schedules.map((schedule) => (
              <Option key={schedule._id} value={schedule._id}>
                {schedule.name}
              </Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "10px",
          }}
        >
          <h4>Travel Date</h4>
          <DatePicker
            format="DD MMM YYYY"
            value={filter.travelDate}
            onChange={(value) => {
              setFilter({ ...filter, travelDate: value });
            }}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <PaginationControls
            currentPage={filter.pageNumber}
            totalPages={Math.ceil(leads.totalCount / filter.pageSize)}
            onClickNext={() => {
              setFilter((prev) => ({
                ...prev,
                pageNumber: prev.pageNumber + 1,
              }));
            }}
            onClickPrev={() => {
              setFilter((prev) => ({
                ...prev,
                pageNumber: prev.pageNumber - 1,
              }));
            }}
          />
        </div>
      </div>
      <CommentsModal
        lead={selectedLeadForComments}
        onClose={() => setSelectedLeadForComments(null)}
        onAddComment={onAddComment}
      />

      <PassengersModal
        lead={selectedLeadForPassengers}
        onClose={() => setSelectedLeadForPassengers(null)}
      />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID / Travel Date</TableCell>
              <TableCell>Package</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Seats</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Payment Details</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.entities.map((lead) => (
              <TableRow key={lead._id}>
                <TableCell>
                  <div>{lead._id}</div>
                  <div>{moment(lead.travelDate).format("DD MMM YYYY")}</div>
                </TableCell>
                <TableCell>{lead.charterSchedule.name}</TableCell>
                <TableCell>
                  <div>{lead.name}</div>
                  <div>{lead.mobileNumber}</div>
                  <div>{lead.email}</div>
                </TableCell>
                <TableCell
                  style={{
                    minWidth: "100px",
                  }}
                >
                  {lead.seats}{" "}
                  <Button
                    icon={<TeamOutlined />}
                    onClick={() => {
                      setSelectedLeadForPassengers(lead);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={lead.status}
                    style={{ width: "110px" }}
                    onChange={(status) => onChangeStatus(lead, status)}
                  >
                    {getAllowedStatuses(lead.status).map((status) => (
                      <Option key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </Option>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  {lead.totalCost ? (
                    <>
                      <div>Total Amount: {lead.totalCost}</div>
                      <div>Amount paid: {lead.amountPaid || 0}</div>
                      <div>Account Manager: {lead.accountManager}</div>
                    </>
                  ) : (
                    "No payment details"
                  )}
                </TableCell>
                <TableCell>
                  {moment(lead.createdAt).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {["prospect", "client"].includes(lead.status) && (
                    <Button
                      icon={<DollarCircleOutlined />}
                      onClick={() => {
                        setSelectedLeadForPaymentUpdate(lead);
                      }}
                    />
                  )}

                  {["prospect", "client"].includes(lead.status) && (
                    <Button
                      icon={<MailOutlined />}
                      onClick={() => {
                        setSelectedLeadForResendEmail(lead);
                      }}
                    />
                  )}
                  <Badge count={lead?.comments?.length || 0}>
                    <Button
                      icon={<MessageOutlined />}
                      onClick={() => {
                        setSelectedLeadForComments(lead);
                      }}
                    />
                  </Badge>
                </TableCell>
              </TableRow>
            ))}

            {leads.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No leads found
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default LeadsPage;
