import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  Link,
} from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import { useRole } from "../../utils/useRole";
import { useCallback } from "react";
import AgentsPage from "./AgentsPage";
import LeadsPage from "./LeadsPage";
import SchedulesPage from "./Schedules";
import BookingsPage from "./BookingsPage";

const ChartersPage = () => {
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const role = useRole();

  const getTabValue = useCallback(() => {
    if (location.pathname === `${url}/bookings`) return 0;
    if (location.pathname === `${url}/leads`) return 1;
    if (location.pathname === `${url}/agents` && role === "admin") return 2;
    if (location.pathname === `${url}/schedules` && role === "admin") return 3;

    return false;
  }, [role, location.pathname, url]);

  return (
    <div>
      <Tabs value={getTabValue()}>
        <Tab label="Package Bookings" component={Link} to={`${url}/bookings`} />
        {role === "admin" && (
          <Tab label="Package Leads" component={Link} to={`${url}/leads`} />
        )}
        {role === "admin" && (
          <Tab label="Package Agents" component={Link} to={`${url}/agents`} />
        )}
        {role === "admin" && (
          <Tab label="Packages" component={Link} to={`${url}/schedules`} />
        )}
      </Tabs>
      <Switch>
        <Redirect exact path="/charters" to="/charters/bookings" />
        <Route path={`${path}/leads`} component={LeadsPage} />
        <Route path={`${path}/agents`} component={AgentsPage} />
        <Route path={`${path}/schedules`} component={SchedulesPage} />
        <Route path={`${path}/bookings`} component={BookingsPage} />
        <Route path="*">
          <div>404</div>
        </Route>
      </Switch>
    </div>
  );
};

export default ChartersPage;
