import { getAuth } from "firebase/auth";

class Api {
  baseUrl = "https://helitaxii-api.helitaxii.com";
  // baseUrl = "https://test.helitaxii.com";
  // baseUrl = "http://localhost:8080";
  async getToken() {
    return await getAuth().currentUser.getIdToken();
  }

  async getJoyRideSchedules(travelDate) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");

    const queryString = travelDate ? `?travelDate=${travelDate}` : "";

    return fetch(`${this.baseUrl}/v2/schedules/joyrides${queryString}`, {
      headers,
    });
  }

  async updateJoyRideScheduleOffDate(offDates, schedule) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");
    headers.append("Content-Type", "application/json");

    return fetch(`${this.baseUrl}/v2/schedules/${schedule._id}/offDates`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        offDates,
      }),
    });
  }

  async getPackages() {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");
    return fetch(`${this.baseUrl}/v2/packages`, {
      headers,
    }).then((res) => res.json());
  }

  async getPackageLeads({
    packageIds = "",
    statuses = "",
    pageNumber = 1,
    pageSize = 10,
  }) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");

    return fetch(
      `${this.baseUrl}/v2/packageLeads?packageIds=${packageIds}&statuses=${statuses}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers,
      }
    ).then((res) => res.json());
  }

  async getPackageLead(id) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");

    return fetch(`${this.baseUrl}/v2/packageLeads/${id}`, {
      headers,
    }).then((res) => res.json());
  }

  async changePackageLeadStatus({ packageLeadId, status }) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");
    headers.append("Content-Type", "application/json");

    return fetch(`${this.baseUrl}/v2/packageLeads/${packageLeadId}/status`, {
      headers,
      method: "PUT",
      body: JSON.stringify({
        status,
      }),
    }).then((res) => res.json());
  }

  async addCommentToPackageLead({ packageLeadId, comment }) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    headers.append("x-helitaxii-client", "browser");
    headers.append("Content-Type", "application/json");

    return fetch(`${this.baseUrl}/v2/packageLeads/${packageLeadId}/comments`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        comment,
      }),
    }).then((res) => res.json());
  }

  async getBookings(travelDate, type) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    return fetch(
      `${this.baseUrl}/booking?travelDate=${travelDate}&type=${type}`,
      {
        headers,
      }
    );
  }
  async getExclusiveCharterRequests({
    pageNumber,
    pageSize,
    type,
    status,
    source,
  }) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    let queryParams = [];

    if (type) {
      queryParams.push(`type=${type}`);
    }

    if (status) {
      queryParams.push(`status=${status}`);
    }

    if (source) {
      queryParams.push(`source=${source}`);
    }

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    return fetch(
      `${this.baseUrl}/v2/booking/exclusive-charter?${queryParams.join("&")}`,
      {
        headers,
      }
    );
  }

  async changeExclusiveCharterRequestStatus(bookingId, status) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);
    return fetch(`${this.baseUrl}/v2/booking/exclusive-charter/status`, {
      method: "POST",
      headers,
      body: JSON.stringify({ bookingId, status }),
    });
  }
  async addExclusiveCharterComment(id, comment) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/booking/exclusive-charter/comment`, {
      method: "POST",
      headers,
      body: JSON.stringify({ id, comment }),
    });
  }

  async getCharterSchedules() {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charter-schedules`, {
      headers,
    }).then((res) => res.json());
  }

  async getAgents() {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/agents`, {
      headers,
    }).then((res) => res.json());
  }

  async addAgentToSchedule({ agentId, scheduleId, defaultSeats }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charter-schedules/${scheduleId}/agent`, {
      method: "PUT",
      headers,
      body: JSON.stringify({ agentId, defaultSeats }),
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error adding agent to schedule");
      }
      return res.json();
    });
  }

  async addAvailabilityException({ scheduleId, agentId, date, seats }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(
      `${this.baseUrl}/v2/charter-schedules/${scheduleId}/agent/${agentId}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify({ date, seats }),
      }
    ).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error adding availability exception");
      }
      return res.json();
    });
  }

  async createAgent({ name, email }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/agents`, {
      method: "POST",
      headers,
      body: JSON.stringify({ name, email }),
    }).then((res) => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Error creating agent");
      }
      return res.json();
    });
  }

  async resetAgentPassword(agentId) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/agents/${agentId}/reset-password`, {
      method: "POST",
      headers,
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error resetting password");
      }
      return res.json();
    });
  }

  async createSchedule({
    name,
    startDate,
    endDate,
    defaultSeats,
    pricePerSeat,
    payableAdvancePerSeat,
  }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charter-schedules`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        name,
        startDate,
        endDate,
        defaultSeats,
        pricePerSeat,
        payableAdvancePerSeat,
      }),
    }).then((res) => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Error creating schedule");
      }
      return res.json();
    });
  }

  async updateSchedule({ name, pricePerSeat, payableAdvancePerSeat, _id }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charter-schedules/${_id}`, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        name,
        pricePerSeat,
        payableAdvancePerSeat,
      }),
    }).then((res) => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Error creating schedule");
      }
      return res.json();
    });
  }

  async getCharterBookings({
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    search = "",
    agentId = "",
    statuses = [],
    scheduleId = "",
    travelDate = "",
  }) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    let queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (sortColumn && sortDirection) {
      queryParams.push(`sortColumn=${sortColumn}`);
      queryParams.push(`sortDirection=${sortDirection}`);
    }

    if (search) {
      queryParams.push(`search=${search}`);
    }

    if (agentId) {
      queryParams.push(`agentId=${agentId}`);
    }

    if (statuses.length) {
      queryParams.push(`statuses=${statuses.join(",")}`);
    }

    if (scheduleId) {
      queryParams.push(`scheduleId=${scheduleId}`);
    }

    if (travelDate) {
      queryParams.push(`travelDate=${travelDate}`);
    }

    return fetch(
      `${this.baseUrl}/v2/charterBookings?${queryParams.join("&")}`,
      {
        headers,
      }
    ).then((res) => res.json());
  }

  async addLeadComment({ leadId, comment }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charterBookings/${leadId}/comments`, {
      method: "POST",
      headers,
      body: JSON.stringify({ comment }),
    }).then((res) => res.json());
  }

  async getAgentScheduleAvailability({ scheduleId, agentId }) {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(
      `${this.baseUrl}/v2/charter-schedules/${scheduleId}/seats?agentId=${agentId}`,
      {
        headers,
      }
    ).then((res) => res.json());
  }

  async addAgentBooking({
    name,
    email,
    mobileNumber,
    travelDate,
    charterSchedule,
    passengers,
    seats,
  }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charterBookings/agent/add`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        name,
        email,
        mobileNumber,
        travelDate,
        charterSchedule,
        passengers,
        seats,
      }),
    }).then((res) => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Error creating booking");
      }
      return res.json();
    });
  }

  async moveToLead({ bookingId }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charterBookings/${bookingId}/moveToLead`, {
      method: "POST",
      headers,
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error moving booking to lead");
      }
      return res.json();
    });
  }

  async closeBooking({ bookingId, sendEmail }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charterBookings/${bookingId}/close`, {
      method: "POST",
      headers,
      body: JSON.stringify({ sendEmail }),
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error closing booking");
      }
      return res.json();
    });
  }

  async moveToProspect({
    bookingId,
    totalCost,
    advance,
    accountManager,
    sendEmail,
  }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(
      `${this.baseUrl}/v2/charterBookings/${bookingId}/moveToProspect`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({ totalCost, advance, accountManager, sendEmail }),
      }
    ).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error moving booking to prospect");
      }
      return res.json();
    });
  }

  async moveToClient({
    bookingId,
    amountPaid,
    transactionId,
    sendTransactionEmail,
    sendPassengerEmail,
  }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(
      `${this.baseUrl}/v2/charterBookings/${bookingId}/moveToClient`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          amountPaid,
          transactionId,
          sendTransactionEmail,
          sendPassengerEmail,
        }),
      }
    ).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error moving booking to client");
      }

      return res.json();
    });
  }

  async updatePayment({ bookingId, transaction, sendTransactionEmail }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/charterBookings/${bookingId}`, {
      method: "PUT",
      headers,
      body: JSON.stringify({ transaction, sendTransactionEmail }),
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error updating payment");
      }
      return res.json();
    });
  }

  async resendEmail(bookingId) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(
      `${this.baseUrl}/v2/charterBookings/${bookingId}/resendEmail`,
      {
        method: "POST",
        headers,
      }
    ).then((res) => {
      if (res.status !== 200) {
        throw new Error("Error resending email");
      }
      return res.json();
    });
  }

  async getSources() {
    const headers = new Headers();
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/booking/exclusive-charter/sources`, {
      headers,
    }).then((res) => res.json());
  }

  async blockSeats({
    schedule,
    mobileNumber,
    email,
    seatCount,
    travelDate,
    fromHalt,
    toHalt,
  }) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `bearer ${await this.getToken()}`);

    return fetch(`${this.baseUrl}/v2/booking/block`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        schedule,
        mobileNumber,
        email,
        seatCount,
        travelDate,
        fromHalt,
        toHalt,
      }),
    });
  }
}

export default new Api();
