import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { useContext, useState } from "react";
import api from "../../api";
import { Button, Input, InputNumber, DatePicker } from "antd";
import { context } from "../../providers/user.provider";
import isNumber from "lodash/isNumber";

const UpdateScheduleModal = ({ onClose, schedule }) => {
  const [scheduleUpdate, setScheduleUpdate] = useState({
    name: schedule.name || "",
    pricePerSeat: schedule.pricePerSeat || 0,
    payableAdvancePerSeat: schedule.payableAdvancePerSeat || 0,
  });
  const userContext = useContext(context);

  const onUpdate = () => {
    if (
      !scheduleUpdate.name ||
      !isNumber(scheduleUpdate.pricePerSeat) ||
      !isNumber(scheduleUpdate.payableAdvancePerSeat)
    ) {
      userContext.showError("Please enter all details.");
      return;
    }

    userContext.showLoading();

    api
      .updateSchedule({
        name: scheduleUpdate.name,
        pricePerSeat: scheduleUpdate.pricePerSeat,
        payableAdvancePerSeat: scheduleUpdate.payableAdvancePerSeat,
        _id: schedule._id,
      })
      .then(() => {
        userContext.showSuccess("Package updated successfully");
        onClose();
      })
      .catch(() => {
        userContext.showError("Error updating package");
      })
      .finally(() => {
        userContext.hideLoading();
      });
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      style={{
        zIndex: 1029,
      }}
    >
      <DialogTitle>Add new Package</DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <h4>Name</h4>
        <Input
          value={scheduleUpdate.name}
          type="text"
          onChange={(e) =>
            setScheduleUpdate({ ...scheduleUpdate, name: e.target.value })
          }
        />

        <h4 style={{ marginTop: "5px" }}>Price per seat</h4>
        <InputNumber
          value={scheduleUpdate.pricePerSeat}
          onChange={(value) =>
            setScheduleUpdate({ ...scheduleUpdate, pricePerSeat: value })
          }
        />

        <h4 style={{ marginTop: "5px" }}>Payable advance per seat</h4>
        <InputNumber
          value={scheduleUpdate.payableAdvancePerSeat}
          onChange={(value) =>
            setScheduleUpdate({
              ...scheduleUpdate,
              payableAdvancePerSeat: value,
            })
          }
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            type="secondary"
            onClick={onCancel}
            style={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={onUpdate}>
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateScheduleModal;
