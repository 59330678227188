import { useCallback, useContext, useEffect, useState } from "react";
import { useRole } from "../../utils/useRole";
import { useHistory } from "react-router-dom";
import api from "../../api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "antd";
import AddAgentModal from "./AddAgentModal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { context } from "../../providers/user.provider";

const AgentsPage = () => {
  const role = useRole();
  const history = useHistory();
  const [agents, setAgents] = useState([]);
  const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);
  const [agentIdForPasswordReset, setAgentIdForPasswordReset] = useState("");
  const userContext = useContext(context);

  const fetchAgents = useCallback(async () => {
    // Fetch schedules here
    userContext.showLoading();
    api
      .getAgents()
      .then((response) => {
        const filteredAgents = (response.agents || []).filter(
          (agent) => !agent.isThumbyAgent
        );
        setAgents(filteredAgents || []);
      })
      .finally(() => {
        userContext.hideLoading();
      });
  }, []);

  const onCloseAddAgentModal = () => {
    fetchAgents();
    setAddAgentModalOpen(false);
  };

  const onAddAgent = () => {
    setAddAgentModalOpen(true);
  };

  const resetAgentPassword = () => {
    userContext.showLoading();
    api
      .resetAgentPassword(agentIdForPasswordReset)
      .then(() => {
        setAgentIdForPasswordReset("");
        userContext.showSuccess("Password reset email sent");
      })
      .catch(() => {
        userContext.showError("Error resetting password");
      })
      .finally(() => {
        userContext.hideLoading();
      });
  };

  useEffect(() => {
    if (!role) return;

    if (role !== "admin") {
      history.replace("/charters/bookings");
      return;
    }

    fetchAgents();
  }, [role, history, fetchAgents]);

  return (
    <div>
      <h1>Package Agents</h1>
      {addAgentModalOpen && <AddAgentModal onClose={onCloseAddAgentModal} />}

      {!!agentIdForPasswordReset && (
        <Dialog
          open
          onClose={() => setAgentIdForPasswordReset("")}
          style={{
            zIndex: 1029,
          }}
        >
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <div>
              <p>Are you sure you want to reset the password?</p>
              <p>
                You are about to reset the password for the{" "}
                {
                  agents.find((agent) => agent._id === agentIdForPasswordReset)
                    .name
                }{" "}
                agent.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
              >
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => setAgentIdForPasswordReset("")}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  disabled={!agentIdForPasswordReset}
                  onClick={resetAgentPassword}
                >
                  Send reset password email
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button type="primary" onClick={onAddAgent}>
          Add Agent
        </Button>
      </div>

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Reset password</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents.map((agent) => (
              <TableRow key={agent._id}>
                <TableCell>{agent.name}</TableCell>
                <TableCell>{agent.email}</TableCell>

                <TableCell>
                  <Button
                    type="primary"
                    onClick={() => setAgentIdForPasswordReset(agent._id)}
                  >
                    Reset Password
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            {agents.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No agents found
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default AgentsPage;
