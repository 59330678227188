import React from "react";

export const context = React.createContext({
  user: null,
  error: "",
  /**
   *
   * @param {string} message
   */
  showSuccess: (message) => {},
  /**
   *
   * @param {string} message
   */
  showError: (message) => {},
  showLoading: () => {},
  hideLoading: () => {},
});
export const UserProvider = context.Provider;
export const UserConsumer = context.Consumer;
