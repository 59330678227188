import { useCallback, useContext, useEffect, useState } from "react";
import { useRole } from "../../utils/useRole";
import { useHistory } from "react-router-dom";
import api from "../../api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Button, Tag } from "antd";
import AddAgentToScheduleModal from "./AddAgentToScheduleModal";
import UpdateAvailabilityModal from "./UpdateAvailabilityModal";
import AddScheduleModal from "./AddScheduleModal";
import { context } from "../../providers/user.provider";
import UpdateScheduleModal from "./UpdateScheduleModal";

const SchedulesPage = () => {
  const role = useRole();
  const history = useHistory();
  const [schedules, setSchedules] = useState([]);
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [addAgentModalProps, setAddAgentModalProps] = useState({
    open: false,
    schedule: null,
  });
  const [addAvailabilityExceptionProps, setAddAvailabilityExceptionProps] =
    useState({
      open: false,
      schedule: null,
    });
  const [selectedScheduleForUpdate, setSelectedScheduleForUpdate] =
    useState(null);

  const userContext = useContext(context);

  const fetchSchedules = useCallback(async () => {
    userContext.showLoading();
    try {
      api.getCharterSchedules().then((response) => {
        setSchedules(response.charterSchedules || []);
      });
    } catch (e) {
      userContext.showError("Error fetching packages");
    } finally {
      userContext.hideLoading();
    }
  }, []);

  const onCloseAvailabilityExceptionModal = () => {
    fetchSchedules();
    setAddAvailabilityExceptionProps({
      open: false,
      schedule: null,
    });
  };

  const onCloseAddAgentModal = () => {
    fetchSchedules();
    setAddAgentModalProps({
      open: false,
      schedule: null,
    });
  };

  const onAddAgent = (schedule) => {
    setAddAgentModalProps({
      open: true,
      schedule,
    });
  };

  const onUpdateAvailability = (schedule) => {
    setAddAvailabilityExceptionProps({
      open: true,
      schedule,
    });
  };

  useEffect(() => {
    if (!role) return;

    if (role !== "admin") {
      history.replace("/charters/bookings");
      return;
    }

    fetchSchedules();
  }, [role, history, fetchSchedules]);

  const getAgentsInSchedule = (schedule) => {
    const agents = schedule.allowedAgents
      .filter((agent) => !agent.isThumbyAgent)
      .map((agent) => (
        <Tag color="green" key={agent._id}>
          {agent.name}
        </Tag>
      ));

    if (agents.length === 0) return <Tag color="red">No agents</Tag>;

    return agents;
  };

  return (
    <div>
      <h1>Packages</h1>
      {addAgentModalProps.open && (
        <AddAgentToScheduleModal
          onClose={onCloseAddAgentModal}
          schedule={addAgentModalProps.schedule}
        />
      )}
      {addAvailabilityExceptionProps.open && (
        <UpdateAvailabilityModal
          onClose={onCloseAvailabilityExceptionModal}
          schedule={addAvailabilityExceptionProps.schedule}
        />
      )}
      {showAddScheduleModal && (
        <AddScheduleModal
          onClose={() => {
            fetchSchedules();
            setShowAddScheduleModal(false);
          }}
        />
      )}
      {selectedScheduleForUpdate && (
        <UpdateScheduleModal
          onClose={() => {
            fetchSchedules();
            setSelectedScheduleForUpdate(null);
          }}
          schedule={selectedScheduleForUpdate}
        />
      )}

      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button type="primary" onClick={() => setShowAddScheduleModal(true)}>
          Add Package
        </Button>
      </div>

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>From Date</TableCell>
              <TableCell>To Date</TableCell>
              <TableCell>Number of Agents</TableCell>
              <TableCell>Add Agent to package</TableCell>
              <TableCell>Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule) => (
              <TableRow key={schedule.id}>
                <TableCell>{schedule.name}</TableCell>
                <TableCell>
                  {moment(schedule.startDate).format("DD MMM YYYY")}
                </TableCell>
                <TableCell>
                  {moment(schedule.endDate).format("DD MMM YYYY")}
                </TableCell>
                <TableCell>{getAgentsInSchedule(schedule)}</TableCell>
                <TableCell>
                  <Button
                    type="default"
                    style={{ alignSelf: "flex-end", marginLeft: "5px" }}
                    onClick={() => onAddAgent(schedule)}
                  >
                    Add Agent to package
                  </Button>
                </TableCell>
                <TableCell>
                  {" "}
                  <Button
                    type="default"
                    style={{ alignSelf: "flex-end", marginLeft: "5px" }}
                    onClick={() => onUpdateAvailability(schedule)}
                  >
                    Update availability
                  </Button>
                  <Button
                    type="default"
                    style={{ alignSelf: "flex-end", marginLeft: "5px" }}
                    onClick={() => {
                      setSelectedScheduleForUpdate(schedule);
                    }}
                  >
                    Update Price
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            {schedules.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No schedules found
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default SchedulesPage;
