import React from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Divider } from "antd";
import {
  CloseOutlined,
  MailOutlined,
  PhoneOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

message.config({
  duration: 1,
});

const CopyTextButton = ({ text }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        message.success(`${text} copied to clipboard`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        message.error("Failed to copy text");
      });
  };

  return (
    <Button
      onClick={handleCopy}
      icon={<CopyOutlined />}
      style={{ height: "16px", border: "none" }}
      type="ghost"
    ></Button>
  );
};

const PassengersModal = ({ lead, onClose }) => {
  if (!lead) {
    return null;
  }

  return (
    <Dialog open={!!lead} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div>Passengers</div>
          </div>
          <div>
            <Button
              type="default"
              style={{ alignSelf: "flex-end", marginLeft: "5px" }}
              icon={<CloseOutlined />}
              onClick={onClose}
            ></Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "600px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ marginBottom: 0 }}>
              Travel Date: {moment(lead.travelDate).format("MMM DD YYYY")}
            </p>
            <p style={{ marginBottom: 0 }}>
              Schedule: {lead?.charterSchedule?.name}
            </p>
            <p style={{ marginBottom: 0 }}>
              <PhoneOutlined />{" "}
              <span style={{ marginLeft: "10px" }}>{lead?.mobileNumber}</span>
              <CopyTextButton text={lead?.mobileNumber} />
            </p>
            <p style={{ marginBottom: 0 }}>
              <MailOutlined />{" "}
              <span style={{ marginLeft: "10px" }}>{lead?.email}</span>
              <CopyTextButton text={lead?.email} />
            </p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ marginBottom: 0, marginLeft: "auto", color: "darkgray" }}>
            Lead created on: {moment(lead.createdAt).format("MMM DD YYYY")}
          </p>
        </div>

        <Divider />
        {lead?.passengers?.length > 0 ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={150}>Name</TableCell>
                  <TableCell width="50px">Age</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>ID Card Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lead.passengers.map((passenger) => (
                  <TableRow key={passenger.id}>
                    <TableCell width={150}>{passenger.name}</TableCell>
                    <TableCell width="50px">{passenger.age}</TableCell>
                    <TableCell>{passenger.gender}</TableCell>
                    <TableCell>{passenger.weight}</TableCell>
                    <TableCell>{passenger.idCardNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <p style={{ marginBottom: 10 }}>No Passenger info</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PassengersModal;
