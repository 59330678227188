import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Table } from "antd";
import API from "../../api";
import classes from "./index.module.css";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import OffDateDialog from "./OffDateDialog";
import { useRole } from "../../utils/useRole";
import { Redirect } from "react-router-dom";

const columns = [
  {
    title: "Name",
    render: (s) => (
      <div>
        <div>{s.name}</div>
      </div>
    ),
  },
  {
    title: "Path",
    render: (s) => (
      <div>
        <div>{s.path.name}</div>
      </div>
    ),
  },
  {
    title: "Time",
    render: (s) => (
      <div>
        <div>{s.arrivalTime.join(" - ")}</div>
      </div>
    ),
  },
  {
    title: "From",
    render: (s) => (
      <div>
        <div>{moment(s.effectiveFromDate).format("DD MMM YYYY")}</div>
      </div>
    ),
  },
  {
    title: "To",
    render: (s) => (
      <div>
        <div>{moment(s.effectiveToDate).format("DD MMM YYYY")}</div>
      </div>
    ),
  },
];

const JoyRidesPage = () => {
  const [schedules, setSchedules] = useState({ data: [], loading: true });
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const role = useRole();

  const getActiveJoyrideSchedules = useCallback(async () => {
    try {
      const { schedules: newSchedules } = await API.getJoyRideSchedules().then(
        (res) => res.json()
      );

      setSchedules({
        data: newSchedules,
        loading: false,
      });
    } catch (err) {
      setSchedules((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const onClickEdit = (newSelectedSchedule) => {
    setSelectedSchedule(newSelectedSchedule);
  };

  const closeEditDialog = () => {
    setSelectedSchedule(null);
  };

  const onSave = async (newOffDates) => {
    try {
      await API.updateJoyRideScheduleOffDate(newOffDates, selectedSchedule);
      onClickEdit();
      getActiveJoyrideSchedules();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getActiveJoyrideSchedules();
  }, [getActiveJoyrideSchedules]);

  const OffDateColumn = {
    title: "Off Dates",
    render: (s) => (
      <div className={classes.editCellWrapper}>
        <div>{s.offDates.length}</div>
        <div>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            onClick={() => onClickEdit(s)}
          />
        </div>
      </div>
    ),
  };

  if (role !== "admin") {
    return <Redirect to="/charters" />;
  }

  return (
    <div>
      <h2 className={classes.alignLeft}>Joyride schedules</h2>
      <div className={classes.tableWrapper}>
        <Card style={{ width: "100%" }}>
          <Table
            loading={schedules.loading}
            style={{
              border: "1px solid lightgray",
            }}
            columns={[...columns, OffDateColumn]}
            dataSource={schedules.data}
            pagination={{ position: ["none", "none"] }}
          />
        </Card>
      </div>
      {selectedSchedule && (
        <OffDateDialog
          schedule={selectedSchedule}
          onClose={closeEditDialog}
          onSave={onSave}
        />
      )}
    </div>
  );
};

export default JoyRidesPage;
