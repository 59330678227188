import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { UserConsumer } from "../../providers/user.provider";
import "./index.css";
const icons = {
  error: ErrorIcon,
};
export default class SnackBarError extends Component {
  render() {
    const closeErrorSnackBar = this.context.hideError;
    return (
      <UserConsumer>
        {({ error }) => {
          return (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={error ? true : false}
              autoHideDuration={3000}
              onClose={closeErrorSnackBar}
            >
              <MySnackbarContent
                onClose={closeErrorSnackBar}
                variant="error"
                message={error}
              />
            </Snackbar>
          );
        }}
      </UserConsumer>
    );
  }
}

SnackBarError.contextType = UserConsumer;

function MySnackbarContent(props) {
  const { message, onClose, variant, ...other } = props;
  const Icon = icons[variant];

  return (
    <SnackbarContent
      className={`snackbar-content ${variant}`}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar">
          <Icon />
          <span
            style={{
              verticalAlign: "super",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            {" "}
            {message}
          </span>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      {...other}
    />
  );
}
