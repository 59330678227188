import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../api";
import { context } from "../../providers/user.provider";
import { Button, Select, DatePicker, Tag } from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import Calendar from "rsuite/Calendar";
import "rsuite/Calendar/styles/index.css";
import { useLocation } from "react-router-dom";
import { useRole } from "../../utils/useRole";
import CreateBookinModal from "./CreateBookingModal";
import {
  MessageOutlined,
  TeamOutlined,
  MailOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import PassengersModal from "./PassengersModal";

const { Option } = Select;

const BookingsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [schedules, setSchedules] = useState([]);
  const [filter, setFilter] = useState({
    travelDate: "",
    scheduleId: "",
  });
  const [bookings, setBookings] = useState({
    entities: [],
    totalCount: 0,
  });
  const [availability, setAvailability] = useState("");
  const userContext = useContext(context);
  const selectedDate = params.get("date");
  const [calendarDefaultDate, setCalendarDefaultDate] = useState(new Date());
  const [showNewBookingModal, setShowNewBookingModal] = useState(false);
  const [agents, setAgents] = useState([]);
  const [availabilityFilter, setAvailabilityFilter] = useState({
    agentId: "",
    scheduleId: "",
  });
  const role = useRole();

  const getBookings = useCallback(() => {
    if (!filter.travelDate) {
      return;
    }

    userContext.showLoading();
    api
      .getCharterBookings({
        ...filter,
        travelDate: filter.travelDate.format("MM/DD/YYYY"),
        statuses: ["client", "prospect"],
      })
      .then((response) => {
        setBookings({
          entities: response.bookings,
          totalCount: response.totalCount,
        });
      })
      .finally(() => {
        userContext.hideLoading();
      });
  }, [filter]);

  const fetchSchedules = useCallback(() => {
    api.getCharterSchedules().then((response) => {
      if (Array.isArray(response.charterSchedules)) {
        setSchedules(response.charterSchedules);
      }

      if (role === "admin" && response.charterSchedules.length) {
        setAvailabilityFilter((prev) => ({
          ...prev,
          scheduleId: response.charterSchedules[0]._id,
        }));
      }
    });
  }, []);

  const getScheduleAvailability = useCallback(async () => {
    if (role === "admin" && !availabilityFilter.agentId) {
      return;
    }

    setAvailability({});

    const scheduleIds = schedules
      .map((schedule) => schedule._id)
      .filter((id, index) => {
        if (role === "agent") {
          return true;
        }
        if (!availabilityFilter.scheduleId) {
          return index === 0;
        }

        return id === availabilityFilter.scheduleId;
      });

    if (!scheduleIds.length) {
      return;
    }

    const promises = [];

    try {
      userContext.showLoading();
      for (const scheduleId of scheduleIds) {
        promises.push(
          api.getAgentScheduleAvailability({
            scheduleId,
            agentId: availabilityFilter.agentId,
          })
        );
      }

      await Promise.all(promises).then((responses) => {
        const availability = {};

        responses.forEach((response) => {
          availability[response.scheduleId] = response.seats;
        });

        console.log(availability);

        setAvailability((prev) => ({
          ...prev,
          ...availability,
        }));
      });
    } catch (e) {
      console.log(e);
    } finally {
      userContext.hideLoading();
    }
  }, [schedules, availabilityFilter, role]);

  useEffect(() => {
    if (selectedDate) {
      setFilter((prev) => ({
        ...prev,
        travelDate: moment(selectedDate),
      }));
    }
  }, [selectedDate]);

  useEffect(() => {
    getBookings();
  }, [getBookings]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  useEffect(() => {
    getScheduleAvailability();
  }, [getScheduleAvailability]);

  useEffect(() => {
    if (role === "admin") {
      api.getAgents().then((response) => {
        setAgents(response.agents);
        const thumbyAgent = response.agents.find(
          (agent) => agent.isThumbyAgent
        );

        if (thumbyAgent) {
          setAvailabilityFilter((prev) => ({
            ...prev,
            agentId: thumbyAgent._id,
          }));
        }
      });
    }
  }, [role]);

  const onDateSelect = (date) => {
    const formattedDate = moment(date).format("MM/DD/YYYY");
    setCalendarDefaultDate(date);
    history.push({
      search: `?date=${formattedDate}`,
    });
  };

  const onBack = () => {
    history.push({
      search: "",
    });
  };

  const renderCell = useCallback(
    (date) => {
      if (!availability) {
        return;
      }

      const scheduleItems = [];

      schedules.forEach((schedule) => {
        let availabilityForDate = availability[schedule._id]
          ? availability[schedule._id][moment(date).format("MM/DD/YYYY")]
          : null;

        if (!availabilityForDate) {
          return;
        }

        if (availabilityForDate.available === null) {
          scheduleItems.push(
            <div
              style={{
                display: "flex",
                border: "1px solid cornflowerblue",
                borderRadius: "5px",
                padding: "1px 2px",
                alignItems: "center",
                marginBottom: "1px",
              }}
            >
              <div style={{ fontSize: "11px", marginRight: "2px" }}>
                {schedule.name}
              </div>
              <Tag color="red">N/A</Tag>
            </div>
          );

          return;
        }

        scheduleItems.push(
          <div
            style={{
              display: "flex",
              border: "1px solid cornflowerblue",
              borderRadius: "5px",
              padding: "1px 2px",
              alignItems: "center",
              marginBottom: "1px",
            }}
          >
            <div style={{ fontSize: "11px" }}>{schedule.name}</div>
            <div
              style={{
                display: "inline",
                marginLeft: "5px",
                fontSize: "11px",
                borderRadius: "50%",
                background: "red",
                width: "16px",
                height: "16px",
                color: "white",
              }}
            >
              {availabilityForDate.totalBookedSeats}
            </div>
            <div
              style={{
                display: "inline",
                marginLeft: "5px",
                fontSize: "11px",
                borderRadius: "50%",
                background: "green",
                width: "16px",
                height: "16px",
                color: "white",
              }}
            >
              {availabilityForDate.available}
            </div>
          </div>
        );
      });

      return <>{scheduleItems.map((si) => si)}</>;
    },
    [availability, schedules]
  );

  return (
    <div>
      {showNewBookingModal && (
        <CreateBookinModal
          schedules={schedules}
          availability={availability}
          onClose={() => {
            setShowNewBookingModal(false);
            getBookings();
            getScheduleAvailability();
          }}
        />
      )}
      <div style={{ display: "flex", marginTop: "5px" }}>
        {selectedDate && (
          <div style={{ marginRight: "5px" }}>
            <Button icon={<LeftOutlined />} onClick={onBack} />
          </div>
        )}
        <h2 style={{ flex: 1 }}>
          Package Bookings{" "}
          {selectedDate
            ? `on ${moment(selectedDate).format("DD MMM YYYY")}`
            : ""}
        </h2>
        {role === "agent" && (
          <Button
            onClick={() => setShowNewBookingModal(true)}
            icon={<PlusOutlined />}
          >
            Create Booking
          </Button>
        )}
      </div>
      {selectedDate ? (
        <DateView
          schedules={schedules}
          date={selectedDate}
          onDateSelect={onDateSelect}
          scheduleId={filter.scheduleId}
          bookings={bookings}
          onScheduleSelect={(scheduleId) => {
            setFilter((prev) => ({
              ...prev,
              scheduleId,
            }));
          }}
        />
      ) : (
        <>
          {role === "admin" && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Select
                style={{ width: "300px" }}
                value={availabilityFilter.agentId}
                onChange={(value) => {
                  setAvailabilityFilter((prev) => ({
                    ...prev,
                    agentId: value,
                  }));
                }}
              >
                {agents.map((agent) => (
                  <Option key={agent._id} value={agent._id}>
                    {agent.name}{" "}
                    {agent.isThumbyAgent && (
                      <Tag color="green">Thumby Aviation</Tag>
                    )}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: "200px" }}
                value={availabilityFilter.scheduleId}
                onChange={(value) => {
                  setAvailabilityFilter((prev) => ({
                    ...prev,
                    scheduleId: value,
                  }));
                }}
              >
                {schedules.map((schedule) => (
                  <Option key={schedule._id} value={schedule._id}>
                    {schedule.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          <Calendar
            onSelect={onDateSelect}
            renderCell={renderCell}
            defaultValue={calendarDefaultDate}
          />
        </>
      )}
    </div>
  );
};

const DateView = ({
  schedules,
  date,
  onDateSelect,
  onScheduleSelect,
  scheduleId,
  bookings,
}) => {
  const [selectedLeadForPassengers, setSelectedLeadForPassengers] =
    useState(null);
  return (
    <>
      <PassengersModal
        lead={selectedLeadForPassengers}
        onClose={() => setSelectedLeadForPassengers(null)}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <h4>Travel Date</h4>
          <DatePicker
            allowClear={false}
            format="DD MMM YYYY"
            value={date ? moment(date) : null}
            onChange={(value) => {
              onDateSelect(value.toISOString());
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "10px",
          }}
        >
          <h4>Schedule</h4>
          <Select
            style={{ width: "200px" }}
            value={scheduleId}
            onChange={(value) => {
              onScheduleSelect(value);
            }}
            allowClear
          >
            {schedules.map((schedule) => (
              <Option key={schedule._id} value={schedule._id}>
                {schedule.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Bookings grouped by charterSchedule */}
      {schedules
        .filter((s) => (scheduleId ? s._id === scheduleId : true))
        .map((schedule) => {
          const scheduleBookings = bookings.entities.filter(
            (booking) => booking.charterSchedule._id === schedule._id
          );

          return (
            <div key={schedule._id} style={{ marginTop: "10px" }}>
              <h3>{schedule.name}</h3>
              {scheduleBookings.length ? (
                scheduleBookings.map((booking) => (
                  <div
                    key={booking._id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid #e8e8e8",
                      borderRadius: "5px",
                      padding: "5px",
                      marginBottom: "5px",
                      background: "white",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <b>Lead Passenger:</b> {booking.name}
                      </div>
                      <div>
                        <b>Phone:</b> {booking.mobileNumber}
                      </div>
                      <div>
                        <b>Email:</b> {booking.email}
                      </div>
                      <div>
                        <b>Seats:</b> {booking.seats}
                      </div>
                    </div>
                    <div>
                      {booking.seats}{" "}
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => {
                          setSelectedLeadForPassengers(booking);
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "left" }}>
                      {booking.totalCost && (
                        <>
                          <div>
                            <b>Total Amount:</b> {booking.totalCost}
                          </div>
                          <div>
                            <b>Amount Paid:</b> {booking.amountPaid}
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <div>
                        <b>Agent:</b> {booking.agentId.name}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No bookings</div>
              )}
            </div>
          );
        })}
    </>
  );
};

export default BookingsPage;
