import React, { useEffect, useState } from "react";
import { Modal, Tag, Divider, DatePicker, Button } from "antd";
import moment from "moment";
import classes from "./index.module.css";

const OffDateDialog = ({ schedule, onSave, onClose }) => {
  const [offDates, setOffDates] = useState([]);

  useEffect(() => {
    if (schedule) {
      setOffDates(schedule.offDates);
    }
  }, [schedule]);

  const removeOffDate = (index) => {
    setOffDates((prev) => {
      const newOffDates = [...prev];
      newOffDates.splice(index, 1);
      return newOffDates;
    });
  };

  const onDateChange = (date) => {
    if (!date) {
      return;
    }
    setOffDates((prev) => [...prev, date.format("MM/DD/YYYY")]);
  };

  const title = schedule ? `${schedule.path.name} - ${schedule.name}` : "";
  const effectiveFromDateHumanReadable = moment(
    schedule.effectiveFromDate
  ).format("DD MMM YYYY");
  const effectiveToDateHumanReadable = moment(schedule.effectiveToDate).format(
    "DD MMM YYYY"
  );
  const effectiveFromDateShort = moment(schedule.effectiveFromDate).format(
    "MM/DD/YYYY"
  );
  const effectiveToDateShort = moment(schedule.effectiveToDate).format(
    "MM/DD/YYYY"
  );

  const handleSave = () => {
    onSave(offDates);
  };

  return (
    <Modal
      title={title}
      visible={!!schedule}
      onOk={handleSave}
      onCancel={onClose}
      okText="Save"
    >
      <div>
        Scheduled from {effectiveFromDateHumanReadable} to{" "}
        {effectiveToDateHumanReadable}
      </div>
      <div>Time - {schedule.arrivalTime.join(" - ")}</div>
      <Divider />
      <h4>Off Dates</h4>
      <DatePicker
        format={"DD MMM YYYY"}
        onChange={onDateChange}
        value={null}
        disabledDate={(d) => {
          return (
            [...offDates].includes(d.format("MM/DD/YYYY")) ||
            d.startOf("day").diff(moment(effectiveFromDateShort), "day") < 0 ||
            d.endOf("day").diff(moment(effectiveToDateShort), "day") > 0
          );
        }}
      />

      <div className={classes.dateWrapper}>
        {offDates.sort().map((date, index) => (
          <Tag
            closable
            onClose={() => removeOffDate(index)}
            key={date}
            color={
              schedule.offDates.includes(moment(date).format("MM/DD/YYYY"))
                ? "default"
                : "green"
            }
          >
            {moment(date).format("DD MMM YYYY")}
          </Tag>
        ))}
      </div>
    </Modal>
  );
};

export default OffDateDialog;
