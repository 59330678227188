import React from "react";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PaginationControls from "../../components/PaginationControls";
import { Select, Spin, Button, Tag, Badge, Input, Divider } from "antd";
import {
  MessageOutlined,
  CloseOutlined,
  MailOutlined,
  PhoneOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

message.config({
  duration: 1,
});

const CopyTextButton = ({ text }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        message.success(`${text} copied to clipboard`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        message.error("Failed to copy text");
      });
  };

  return (
    <Button
      onClick={handleCopy}
      icon={<CopyOutlined />}
      style={{ height: "16px", border: "none" }}
      type="ghost"
    ></Button>
  );
};

const { TextArea } = Input;

const { Option } = Select;

const getStatus = (status) => {
  switch (status) {
    case 0:
      return (
        <Tag
          style={{
            borderRadius: "5px",
          }}
          color="processing"
        >
          New
        </Tag>
      );
    case 1:
      return (
        <Tag
          style={{
            borderRadius: "5px",
          }}
          color="warning"
        >
          Contacted
        </Tag>
      );
    case 2:
      return (
        <Tag
          style={{
            borderRadius: "5px",
          }}
          color="success"
        >
          Lead
        </Tag>
      );
    default:
      return "";
  }
};

const CommentsModal = ({ selectedExclusiveCharter, onClose, onAddComment }) => {
  const [comment, setComment] = React.useState("");

  if (!selectedExclusiveCharter) {
    return null;
  }

  return (
    <Dialog open={!!selectedExclusiveCharter} onClose={onClose}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div>Comments</div>
          </div>
          <div>
            <Button
              type="default"
              style={{ alignSelf: "flex-end", marginLeft: "5px" }}
              icon={<CloseOutlined />}
              onClick={onClose}
            ></Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ marginBottom: 0 }}>
              Travel Date:{" "}
              {moment(selectedExclusiveCharter.travelDateTimeStamp).format(
                "MMM DD YYYY"
              )}
            </p>
            <p style={{ marginBottom: 0 }}>
              Type: {selectedExclusiveCharter?.fromLocation}
            </p>
            <p style={{ marginBottom: 0 }}>
              <PhoneOutlined />{" "}
              <span style={{ marginLeft: "10px" }}>
                {selectedExclusiveCharter?.contactNumber}
              </span>
              <CopyTextButton text={selectedExclusiveCharter?.contactNumber} />
            </p>
            <p style={{ marginBottom: 0 }}>
              <MailOutlined />{" "}
              <span style={{ marginLeft: "10px" }}>
                {selectedExclusiveCharter?.email}
              </span>
              <CopyTextButton text={selectedExclusiveCharter?.email} />
            </p>
          </div>
          <div>{getStatus(selectedExclusiveCharter.status)}</div>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ marginBottom: 0, marginLeft: "auto", color: "darkgray" }}>
            Request raised on:{" "}
            {moment(selectedExclusiveCharter.createdAt).format("MMM DD YYYY")}
          </p>
        </div>

        <Divider />
        {selectedExclusiveCharter?.comments?.length > 0 ? (
          <>
            {selectedExclusiveCharter.comments.map((comment) => (
              <div style={{ marginTop: 10 }}>
                <div style={{ marginBottom: 5 }}>
                  <span style={{ color: "gray" }}>Admin</span>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: comment,
                  }}
                ></div>
              </div>
            ))}
          </>
        ) : (
          <p style={{ marginBottom: 10 }}>0 Comments</p>
        )}
        <Divider />

        <div style={{ display: "flex" }}>
          <TextArea
            placeholder="Add Comment"
            rows={3}
            onChange={({ target: { value } }) => setComment(value)}
            value={comment}
          />
          <Button
            type="primary"
            style={{ alignSelf: "flex-end", marginLeft: "5px" }}
            onClick={() => {
              onAddComment(selectedExclusiveCharter._id, comment);
              setComment("");
            }}
          >
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const Actions = ({ status, onChangeStatus, id }) => {
  if (status === 0) {
    return (
      <div>
        <Button
          type="secondary"
          onClick={() => onChangeStatus(id, 1)}
          size="small"
          style={{ marginRight: "5px" }}
        >
          Mark as Contacted
        </Button>
        <Button
          type="secondary"
          onClick={() => onChangeStatus(id, 2)}
          size="small"
        >
          Mark as Lead
        </Button>
      </div>
    );
  }

  if (status === 1) {
    return (
      <Button
        type="secondary"
        onClick={() => onChangeStatus(id, 2)}
        size="small"
      >
        Mark as Lead
      </Button>
    );
  }

  if (status === 2) {
    return (
      <Button
        type="secondary"
        onClick={() => onChangeStatus(id, 1)}
        size="small"
      >
        Mark as Contacted
      </Button>
    );
  }

  return null;
};

const RequestsTable = ({
  exclusiveCharters = [],
  pageNumber,
  pageSize,
  totalCount,
  onSelectStatus = () => {},
  onClearStatus = () => {},
  onClickNext = () => {},
  onClickPrev = () => {},
  onChangeStatus = (status) => {},
  loading,
  onCommentsView = () => {},
  selectedExclusiveCharter = null,
  onAddComment,
  onClose,
  onSelectType = () => {},
  sources = [],
  onSelectSource = () => {},
  status = "",
  type = "",
  source = "",
}) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          <span style={{ marginRight: "10px" }}>Type:</span>
          <Select
            defaultValue="Chardham"
            labelInValue
            placeholder="Select Type"
            style={{ width: 150 }}
            onChange={onSelectType}
            value={type}
          >
            <Option value="Chardham">Chardham</Option>
            <Option value="General">General</Option>
            <Option value="">Others</Option>
          </Select>
        </div>
        <div style={{ marginRight: "10px" }}>
          <span style={{ marginRight: "10px" }}>Sources:</span>
          <Select
            defaultValue=""
            labelInValue
            placeholder="Select Source"
            style={{ width: 150 }}
            onChange={onSelectSource}
            value={source}
          >
            <Option value="">All</Option>
            {sources
              .filter((source) => !!source)
              .map((source) => (
                <Option key={source} value={source}>
                  {source.name}
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <span style={{ marginRight: "10px" }}>Status:</span>
          <Select
            defaultValue=""
            allowClear
            labelInValue
            placeholder="Select Status"
            style={{ width: 150 }}
            onChange={onSelectStatus}
            onClear={onClearStatus}
            value={status}
          >
            <Option value="">All</Option>
            <Option value="0">New</Option>
            <Option value="1">Contacted</Option>
            <Option value="2">Lead</Option>
          </Select>
        </div>
        <PaginationControls
          currentPage={pageNumber}
          totalPages={Math.ceil(totalCount / pageSize)}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      </div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Travel Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Mobile No</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              exclusiveCharters.map((ec) => (
                <TableRow key={ec._id}>
                  <TableCell>
                    {moment(ec.travelDateTimeStamp).format("MMM DD YYYY")}
                  </TableCell>
                  <TableCell width={"250px"}>{ec.fromLocation}</TableCell>
                  <TableCell>{ec.campaign?.utmSource}</TableCell>
                  <TableCell>
                    {ec.contactNumber}{" "}
                    <CopyTextButton text={ec.contactNumber} />
                  </TableCell>
                  <TableCell>
                    {ec.email} <CopyTextButton text={ec.email} />
                  </TableCell>
                  <TableCell width={"50px"}>{getStatus(ec.status)}</TableCell>
                  <TableCell>
                    <Actions
                      status={ec.status}
                      onChangeStatus={onChangeStatus}
                      id={ec._id}
                    />
                  </TableCell>
                  <TableCell>
                    <Badge count={ec?.comments?.length || 0}>
                      <Button
                        icon={<MessageOutlined />}
                        onClick={() => onCommentsView(ec)}
                      />
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && exclusiveCharters.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: "center" }}>
                  No Records Found
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: "center" }}>
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <CommentsModal
        selectedExclusiveCharter={selectedExclusiveCharter}
        onAddComment={onAddComment}
        onClose={onClose}
      />
    </div>
  );
};

export default RequestsTable;
