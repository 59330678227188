import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import React from "react";
import {
  BookingsPage,
  LoginPage,
  ExclusiveChartersPage,
  NotFoundPage,
  JoyRidesPage,
  ChartersPage,
} from "./pages";
import { UserConsumer } from "./providers/user.provider";

function Routes() {
  return (
    <Switch>
      <Redirect exact path="/" to="/charters" />
      <Redirect exact path="/exclusive-charters" to="/charters" />
      <ProtectedRoute path="/charters" component={ChartersPage} />
      <ProtectedRoute exact path="/bookings" component={BookingsPage} />
      <ProtectedRoute
        exact
        path="/callback-requests"
        component={ExclusiveChartersPage}
      />
      <ProtectedRoute exact path="/joyRides" component={JoyRidesPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}

function ProtectedRoute({ component: Component, ...props }) {
  const location = useLocation();

  return (
    <UserConsumer>
      {({ user }) => {
        if (user) {
          return <Route {...props} component={Component} />;
        }
        return (
          <Redirect to={`/login#${location.pathname}${location.search}`} />
        );
      }}
    </UserConsumer>
  );
}

export default Routes;
