import React, { useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/PowerOff";
import BookingsIcon from "@material-ui/icons/FlightTakeoff";
import ExclusiveCharterIcon from "@material-ui/icons/Schedule";
import EventNote from "@material-ui/icons/EventNote";
import PublicIcon from "@material-ui/icons/Public";
import { context, UserConsumer } from "../../providers/user.provider";
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import "./index.css";
import { useRole } from "../../utils/useRole";
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/helitaxii.appspot.com/o/35561521738665979.png?alt=media&token=a873912b-9191-4d25-99fd-4094935ddcba";

const routes = [
  {
    name: "Bookings",
    path: "/bookings",
    icon: <BookingsIcon />,
    roles: ["admin"],
  },
  {
    name: "Joy Rides",
    icon: <EventNote />,
    path: "/joyRides",
    roles: ["admin"],
  },
  {
    name: "Callback Requests",
    path: "/callback-requests",
    icon: <ExclusiveCharterIcon />,
    roles: ["admin"],
  },
  {
    name: "Charters",
    path: "/charters",
    icon: <PublicIcon />,
    roles: ["admin", "agent"],
  },
];

const HelitaxiiHeaderComponent = () => {
  const role = useRole();
  const currentContext = useContext(context);

  const logout = () => {
    currentContext.showLoading();

    signOut(getAuth())
      .then((res) => {
        currentContext.unsetUser();
      })
      .finally(() => {
        currentContext.hideLoading();
      });
  };

  return (
    <UserConsumer>
      {({ user }) => {
        return (
          <div>
            <CssBaseline />
            <AppBar position="fixed" style={{ zIndex: 11 }}>
              <Toolbar>
                <IconButton>
                  <img
                    className="header__logo"
                    src={Logo}
                    alt="HeliTaxii"
                    style={{ height: "50px", minWidth: "150px" }}
                  />
                </IconButton>
              </Toolbar>
            </AppBar>
            {user ? (
              <div style={{ zIndex: 10, position: "relative" }}>
                <Drawer
                  variant="permanent"
                  className="drawer"
                  classes={{ paper: "drawer-paper" }}
                >
                  <div style={{ height: "80px", width: "50px" }} />
                  <List>
                    {routes
                      .filter((route) => route.roles.includes(role))
                      .map(({ name, path, icon }, index) => (
                        <Link to={path} key={name}>
                          <ListItem button>
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText
                              primary={name}
                              className="list-text"
                            />
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                  <Divider />
                  <List>
                    {
                      <ListItem button key={"Logout"} onClick={logout}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Logout"}
                          className="list-text"
                        />
                      </ListItem>
                    }
                  </List>
                </Drawer>
              </div>
            ) : (
              <div style={{ height: "80px", width: "240px" }} />
            )}
          </div>
        );
      }}
    </UserConsumer>
  );
};

export default HelitaxiiHeaderComponent;
