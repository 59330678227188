import React, { Component } from "react";
import "./style.css";

class BoxLoader extends Component {
  render() {
    return (
      <div id="loader-overlay">
        <div id="loader">
          <div id="box" />
        </div>
      </div>
    );
  }
}

export default BoxLoader;
