import React, { Component } from "react";
import "./App.css";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import HeaderComponent from "./components/Header";
import { UserProvider } from "./providers/user.provider";
import SnackbarError from "./components/SnackbarError";
import SnackbarSuccess from "./components/SnackbarSuccess";
import BoxLoader from "./components/BoxLoader";
import Router from "./Router";
import { withRouter } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      error: "",
      success: "",
      loading: false,
      role: "",
    };

    this.initializeFirebaseAuthChangeListener();
  }

  initializeFirebaseAuthChangeListener = () => {
    onAuthStateChanged(getAuth(), async (user) => {
      this.showLoading();
      if (user) {
        const idToken = await getAuth().currentUser.getIdToken();
        const { admin, agent } = JSON.parse(atob(idToken.split(".")[1]));
        const {
          location: { hash },
        } = this.props;

        const redirectTo = hash ? hash.substring(1) : "/";

        if (admin || agent) {
          this.setUser(user);
          this.props.history.replace(redirectTo);
        } else {
          this.showError("No Admin privileges!");
        }
      } else {
        this.unsetUser();
      }

      this.hideLoading();
    });
  };

  showError = (error) => {
    this.setState({ error });
  };

  hideError = () => {
    this.setState({ error: "" });
  };

  showSuccess = (success) => {
    this.setState({ success });
  };

  hideSuccess = () => {
    this.setState({ success: "" });
  };

  setUser = (user) => {
    this.setState({ user });
  };

  unsetUser = () => {
    this.setState({ user: null });
  };

  setRole = (role) => {
    this.setState({ role });
  };

  unsetRole = () => {
    this.setState({ role: "" });
  };

  showLoading = () => {
    this.setState({
      loading: true,
    });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const { user, error, loading, success, role } = this.state;
    return (
      <div className="App">
        <UserProvider
          value={{
            user,
            error,
            success,
            loading,
            role,
            setUser: this.setUser.bind(this),
            unsetUser: this.unsetUser.bind(this),
            showError: this.showError.bind(this),
            hideError: this.hideError.bind(this),
            showSuccess: this.showSuccess.bind(this),
            hideSuccess: this.hideSuccess.bind(this),
            showLoading: this.showLoading.bind(this),
            hideLoading: this.hideLoading.bind(this),
            setRole: this.setRole.bind(this),
            unsetRole: this.unsetRole.bind(this),
          }}
        >
          <SnackbarError />
          <SnackbarSuccess />
          <HeaderComponent />
          {loading && <BoxLoader />}
          <div
            style={{
              margin: "0px 20px",
              paddingTop: user ? "100px" : 0,
              marginLeft: user ? "80px" : "20px",
            }}
          >
            <Router />
          </div>
        </UserProvider>
      </div>
    );
  }
}

export default withRouter(App);
