import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";

export default class PaginationControls extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      currentPage: PropTypes.number.isRequired,
      totalPages: PropTypes.number.isRequired,
      onClickNext: PropTypes.func.isRequired,
      onClickPrev: PropTypes.func.isRequired,
    };
  }
  render() {
    const { onClickPrev, onClickNext, currentPage, totalPages } = this.props;

    if (totalPages === 0) {
      return <div style={{ height: "48px" }}></div>;
    }

    return (
      <div>
        <IconButton onClick={onClickPrev} disabled={currentPage === 1}>
          <ArrowBack />
        </IconButton>
        <span>{currentPage + " of " + totalPages + " Pages"}</span>
        <IconButton onClick={onClickNext} disabled={currentPage === totalPages}>
          <ArrowForward />
        </IconButton>
      </div>
    );
  }
}
