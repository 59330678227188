import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Select, Button, Divider, InputNumber, DatePicker, Tag } from "antd";
import { context } from "../../providers/user.provider";
import moment from "moment";
const { Option } = Select;

const UpdateAvailabilityModal = ({ onClose, schedule }) => {
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [date, setDate] = useState("");
  const [exceptionSeats, setExceptionSeats] = useState(0);
  const userContext = useContext(context);

  const onAdd = () => {
    api
      .addAvailabilityException({
        agentId: selectedAgentId,
        scheduleId: schedule._id,
        date: (date && moment(date).format("MM/DD/YYYY")) || "",
        seats: exceptionSeats,
      })
      .then(() => {
        userContext.showSuccess(
          `Availability for ${date.format("DD MMM YYYY")}  added successfully`
        );
        onClose();
      })
      .catch(() => {
        userContext.showError("Error updating the availability");
      });
  };

  const onCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!date) {
      setExceptionSeats(0);
    }

    if (!selectedAgentId) {
      setExceptionSeats(0);
    }

    if (date && selectedAgentId) {
      const dateInMMDDYYYY = moment(date).format("MM/DD/YYYY");
      const exception = schedule.availabilityExceptions.find(
        (exception) =>
          exception.date === dateInMMDDYYYY &&
          exception.agentId === selectedAgentId
      );

      setExceptionSeats(
        exception?.seats || schedule.defaultSeats[selectedAgentId]
      );
    }
  }, [date, selectedAgentId, schedule]);

  const { startDate, endDate } = schedule;

  return (
    <Dialog
      open
      onClose={onClose}
      style={{
        zIndex: 1029,
      }}
    >
      <DialogTitle>Update schedule availability for agent</DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <div>
          <h3>{schedule.name}</h3>
        </div>
        <Divider />
        <h4>Agent</h4>
        <Select
          labelInValue
          placeholder="Select Agent"
          style={{ width: 400 }}
          onChange={(agent) => setSelectedAgentId(agent.value)}
        >
          {schedule.allowedAgents.map((agent) => (
            <Option key={agent._id} value={agent._id}>
              {agent.name}{" "}
              {agent.isThumbyAgent && <Tag color="green">Thumby Aviation</Tag>}
            </Option>
          ))}
        </Select>

        <h4 style={{ marginTop: "5px" }}>Date</h4>
        <DatePicker
          format={"DD MMM YYYY"}
          onChange={(date) => setDate(date)}
          disabledDate={(current) => {
            return (
              current &&
              (current < moment(startDate).startOf("day") ||
                current > moment(endDate).endOf("day"))
            );
          }}
        />

        <div>
          <h4 style={{ marginTop: "5px" }}>Seats</h4>
          <InputNumber
            value={exceptionSeats}
            onChange={setExceptionSeats}
            disabled={!selectedAgentId || !date}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="secondary"
            onClick={onCancel}
            style={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={onAdd}
            disabled={!selectedAgentId || !date}
          >
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAvailabilityModal;
