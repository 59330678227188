import React, { Component } from "react";
import { UserConsumer } from "../../providers/user.provider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./index.css";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
  }

  handleLoginClick = async () => {
    this.context.showLoading();
    await this.login();
  };

  login = async () => {
    const { email, password } = this.state;
    await signOut(getAuth());

    signInWithEmailAndPassword(getAuth(), email, password)
      .catch((err) => {
        const errorMessage = "Uh Oh! Incorrect password.";
        this.context.showError(errorMessage);
        this.setState({
          errorMessage,
          loading: false,
        });
      })
      .finally(() => {
        this.context.hideLoading();
      });
  };

  handleChange = (field, { target: { value } }) => {
    this.setState({
      [field]: value,
    });
  };

  render() {
    const { errorMessage } = this.state;
    return (
      <Card className="login-card">
        <CardContent>
          <div
            style={{
              color: "red",
              minHeight: "12px",
              height: "12px",
              width: "100%",
            }}
          >
            {errorMessage}
          </div>
          <div>
            <TextField
              className="login-input email"
              id="outlined-name"
              label="Email"
              fullWidth
              onChange={(e) => this.handleChange("email", e)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-email"
              label="Password"
              className="login-input password"
              fullWidth
              type="password"
              onChange={(e) => this.handleChange("password", e)}
              margin="normal"
              variant="outlined"
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className="btn login-btn"
              onClick={this.handleLoginClick}
            >
              Login
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
}
LoginPage.contextType = UserConsumer;
