import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Input, Divider, Radio, InputNumber } from "antd";
import api from "../../api";
import { useContext } from "react";
import { context } from "../../providers/user.provider";
import { useState } from "react";
import BookingSummary from "./BookingSummary";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import isBoolean from "lodash/isBoolean";

const UpdatePaymentModal = ({ onClose, booking }) => {
  const userContext = useContext(context);
  const [payment, setPayment] = useState({
    amountPaid: "",
    transactionId: "",
    sendTransactionEmail: null,
  });

  const onUpdatePayment = () => {
    userContext.showLoading();
    api
      .updatePayment({
        bookingId: booking._id,
        transaction: {
          amount: payment.amountPaid,
          transactionId: payment.transactionId,
        },
        sendTransactionEmail: payment.sendTransactionEmail,
      })
      .then((res) => {
        userContext.showSuccess("Payment updated successfully");
        onClose(res.booking);
      })
      .catch(() => {
        userContext.showError("Failed to update payment");
        onClose();
      });
    userContext.hideLoading();
  };

  const isAddTransactionEnabled =
    isBoolean(payment.sendTransactionEmail) &&
    payment.amountPaid > 0 &&
    !!payment.transactionId.length;

  return (
    <Dialog
      open
      onClose={() => onClose()}
      style={{
        zIndex: 1029,
      }}
      fullWidth
    >
      <DialogTitle>Payment details</DialogTitle>
      <DialogContent>
        <div>
          <BookingSummary booking={booking} />
          <Divider style={{ marginBottom: "5px" }} />
          {booking?.transactions?.length > 0 ? (
            <>
              <Table style={{ marginBottom: 10 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {booking.transactions.map((transaction) => (
                    <TableRow key={transaction._id}>
                      <TableCell>{transaction.transactionId}</TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <p style={{ marginBottom: 10 }}>No Passenger info</p>
          )}
          <h3>
            <b>Add new payment</b>
          </h3>
          <div>
            <p>Amount paid</p>
            <InputNumber
              value={payment.amountPaid}
              onChange={(amountPaid) => setPayment({ ...payment, amountPaid })}
            />
          </div>
          <div style={{ marginTop: "5px" }}>
            <p>Transaction ID</p>
            <Input
              type="text"
              value={payment.transactionId}
              onChange={(e) =>
                setPayment({ ...payment, transactionId: e.target.value })
              }
            />
          </div>
          <div style={{ marginTop: "5px" }}>
            <p>Send transaction email</p>
            <Radio.Group
              onChange={(e) => {
                console.log(e.target.value);
                setPayment({
                  ...payment,
                  sendTransactionEmail: e.target.value,
                });
              }}
              value={payment.sendTransactionEmail}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button style={{ marginRight: 10 }} onClick={() => onClose()}>
              Close
            </Button>
            <Button
              type="primary"
              onClick={onUpdatePayment}
              disabled={!isAddTransactionEnabled}
            >
              Add new payment
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePaymentModal;
