import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Input, Select, DatePicker, message } from "antd";
import { useContext, useMemo, useState } from "react";
import moment from "moment";
import api from "../../api";
import { context } from "../../providers/user.provider";
const { Option } = Select;

const CreateBookingModal = ({ onClose, schedules, availability }) => {
  const userContext = useContext(context);
  const [newBooking, setNewBooking] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    travelDate: "",
    charterSchedule: "",
    passengers: [],
    seats: 0,
  });
  const [error, setError] = useState("");

  const onBook = () => {
    const { valid, message } = isBookingValid();
    if (!valid) {
      setError(message);
      return;
    }

    createBooking();
  };

  const createBooking = () => {
    userContext.showLoading();
    api
      .addAgentBooking({
        ...newBooking,
        travelDate: newBooking.travelDate.format("MM/DD/YYYY"),
      })
      .then(() => {
        userContext.showSuccess("Booking created successfully");
        userContext.hideLoading();
        onClose();
      })
      .catch(() => {
        userContext.showError("Error creating booking");
        userContext.hideLoading();
      });
  };

  const onChangePassenger = (index, key, value) => {
    const newPassengers = [...newBooking.passengers];
    newPassengers[index] = {
      ...newPassengers[index],
      [key]: value,
    };

    setNewBooking({
      ...newBooking,
      passengers: newPassengers,
    });
  };

  const isBookingValid = () => {
    if (!newBooking.charterSchedule) {
      return {
        valid: false,
        message: "Please select a schedule",
      };
    }

    if (!newBooking.travelDate?._isValid) {
      return {
        valid: false,
        message: "Please select a travel date",
      };
    }

    if (!newBooking.name.trim()) {
      return {
        valid: false,
        message: "Please enter name",
      };
    }

    if (!newBooking.email.trim()) {
      return {
        valid: false,
        message: "Please enter email",
      };
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(newBooking.email)) {
      return {
        valid: false,
        message: "Please enter a valid email",
      };
    }

    const mobileNumberRegex = /^(\+)?\d{10,12}$/;

    if (!mobileNumberRegex.test(newBooking.mobileNumber)) {
      return {
        valid: false,
        message: "Please enter a valid mobile number",
      };
    }

    if (!newBooking.mobileNumber) {
      return {
        valid: false,
        message: "Please enter mobile number",
      };
    }

    if (!newBooking.seats) {
      return {
        valid: false,
        message: "Please select number of seats",
      };
    }

    if (newBooking.passengers.length !== newBooking.seats) {
      return {
        valid: false,
        message: "Please enter details for all passengers",
      };
    }

    if (
      newBooking.passengers.some((p) => !(p.name.trim() || p.name.length > 3))
    ) {
      return {
        valid: false,
        message: "Please enter name for all passengers",
      };
    }

    if (newBooking.passengers.some((p) => !p.weight)) {
      return {
        valid: false,
        message: "Please enter weight for all passengers",
      };
    }

    if (newBooking.passengers.some((p) => !p.age)) {
      return {
        valid: false,
        message: "Please enter age for all passengers",
      };
    }

    if (newBooking.passengers.some((p) => !["M", "F"].includes(p.gender))) {
      return {
        valid: false,
        message: "Please enter gender for all passengers",
      };
    }

    if (newBooking.passengers.some((p) => !p.idCardNumber?.trim())) {
      return {
        valid: false,
        message: "Please enter Aadhar Number for all passengers",
      };
    }

    return {
      valid: true,
    };
  };

  const availableSeats = useMemo(() => {
    if (!availability || !newBooking.charterSchedule || !newBooking.travelDate)
      return 0;

    if (!availability[newBooking.charterSchedule]) return 0;

    if (
      !availability[newBooking.charterSchedule][
        newBooking.travelDate.format("MM/DD/YYYY")
      ]
    )
      return 0;

    return availability[newBooking.charterSchedule][
      newBooking.travelDate.format("MM/DD/YYYY")
    ].available;
  }, [availability, newBooking.charterSchedule, newBooking.travelDate]);

  console.log(newBooking);

  return (
    <Dialog
      open
      onClose={onClose}
      style={{
        zIndex: 1029,
      }}
      fullWidth
    >
      <DialogTitle>New Booking</DialogTitle>
      <DialogContent>
        <div>
          <h4>Charter Schedule</h4>
          <Select
            style={{ width: "100%" }}
            value={newBooking.charterSchedule}
            onChange={(value) => {
              setNewBooking({
                ...newBooking,
                charterSchedule: value,
              });
            }}
          >
            {schedules.map((schedule) => (
              <Option key={schedule._id} value={schedule._id}>
                {schedule.name}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <h4>Travel Date</h4>
          <DatePicker
            allowClear={false}
            format="DD MMM YYYY"
            value={newBooking.travelDate}
            disabled={!newBooking.charterSchedule}
            disabledDate={(current) => {
              if (!newBooking.charterSchedule) return true;

              const newBookingSchedule = schedules.find(
                (s) => s._id === newBooking.charterSchedule
              );

              if (!newBookingSchedule) return true;

              const startDateMoment = moment(newBookingSchedule.startDate);
              const endDateMoment = moment(newBookingSchedule.endDate);

              return (
                current &&
                (current < startDateMoment.startOf("day") ||
                  current > endDateMoment.endOf("day"))
              );
            }}
            onChange={(value) => {
              setNewBooking({
                ...newBooking,
                travelDate: value ? value : "",
              });
            }}
          />
          <div>
            <h4>Name</h4>
            <Input
              value={newBooking.name}
              disabled={availableSeats === 0}
              onChange={(e) => {
                setNewBooking({
                  ...newBooking,
                  name: e.target.value.trim(),
                });
              }}
            />
          </div>
          <div>
            <h4>Email</h4>
            <Input
              type="email"
              value={newBooking.email}
              disabled={availableSeats === 0}
              onChange={(e) => {
                setNewBooking({
                  ...newBooking,
                  email: e.target.value.trim(),
                });
              }}
            />
          </div>

          <div>
            <h4>Mobile Number</h4>
            <Input
              value={newBooking.mobileNumber}
              disabled={availableSeats === 0}
              onChange={(e) => {
                setNewBooking({
                  ...newBooking,
                  mobileNumber: e.target.value.trim(),
                });
              }}
            />
          </div>

          <div>
            <h4>Seats</h4>
            <Select
              disabled={availableSeats === 0}
              style={{ width: "100%" }}
              value={newBooking.seats}
              onChange={(value) => {
                setNewBooking({
                  ...newBooking,
                  seats: value,
                  passengers: [...newBooking.passengers].slice(0, value),
                });
              }}
            >
              {[...Array(availableSeats).keys()].map((i) => (
                <Option key={i + 1} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <h4>Passengers</h4>
            {[...Array(newBooking.seats).keys()].map((i) => (
              <div key={i} style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <Input
                    disabled={availableSeats === 0}
                    value={newBooking.passengers[i]?.name || ""}
                    placeholder="Name"
                    onChange={(e) =>
                      onChangePassenger(i, "name", e.target.value)
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Input
                    disabled={availableSeats === 0}
                    type="number"
                    value={newBooking.passengers[i]?.weight}
                    placeholder="Weight"
                    onChange={(e) =>
                      onChangePassenger(i, "weight", e.target.value)
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Input
                    disabled={availableSeats === 0}
                    type="number"
                    value={newBooking.passengers[i]?.age}
                    placeholder="Age"
                    onChange={(e) =>
                      onChangePassenger(i, "age", e.target.value)
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Select
                    disabled={availableSeats === 0}
                    value={newBooking.passengers[i]?.gender}
                    placeholder="Gender"
                    onChange={(value) => onChangePassenger(i, "gender", value)}
                    style={{ width: "100%" }}
                  >
                    <Option value="M">Male</Option>
                    <Option value="F">Female</Option>
                  </Select>
                </div>
                <div style={{ width: "100%" }}>
                  <Input
                    disabled={availableSeats === 0}
                    type="text"
                    value={newBooking.passengers[i]?.idCardNumber}
                    placeholder="Aadhar Number"
                    onChange={(e) =>
                      onChangePassenger(i, "idCardNumber", e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: 12,
            color: "red",
          }}
        >
          {error}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button style={{ marginRight: 10 }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            // disabled={!isBookingValid().valid}
            onClick={onBook}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateBookingModal;
