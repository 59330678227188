import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Input, InputNumber, Radio } from "antd";
import api from "../../api";
import { useContext, useState } from "react";
import { context } from "../../providers/user.provider";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";

const StatusChangeModal = ({ onClose, newStatus, booking }) => {
  const userContext = useContext(context);
  const [newProspectForm, setNewProspectForm] = useState({
    costPerSeat: booking?.charterSchedule?.pricePerSeat || 0,
    advance:
      booking.advanceAmount ||
      booking?.charterSchedule?.payableAdvancePerSeat * booking.seats ||
      0,
    accountManager: booking.accountManager || "",
    sendEmail: null,
  });
  const [newClientForm, setNewClientForm] = useState({
    amountPaid: "",
    transactionId: "",
    sendTransactionEmail: null,
    sendPassengerEmail: null,
  });

  const [closeBookingForm, setCloseBookingForm] = useState({
    sendEmail: null,
  });

  const getTitle = () => {
    if (newStatus === "closed") {
      return "Close Booking";
    }

    if (newStatus === "lead") {
      return "Convert to Lead";
    }

    if (newStatus === "prospect") {
      return "Convert to Prospect";
    }
  };

  const getContent = () => {
    if (newStatus === "closed") {
      return (
        <div>
          <p>Are you sure you want to close the booking?</p>
          {(booking.status === "prospect" || booking.status === "client") && (
            <div>
              <div
                style={{
                  marginTop: 10,
                }}
              >
                <p>Send cancellation email to client</p>
                <Radio.Group
                  onChange={(e) => {
                    setCloseBookingForm({
                      ...closeBookingForm,
                      sendEmail: e.target.value,
                    });
                  }}
                  value={closeBookingForm.sendEmail}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (newStatus === "lead") {
      return (
        <div>
          <p>Are you sure you want to convert the booking to a lead?</p>
        </div>
      );
    }

    if (newStatus === "prospect") {
      return (
        <div>
          <p>Enter the details of the new prospect</p>
          <div>
            <p>Per pax cost</p>
            <Input
              value={newProspectForm.costPerSeat}
              type="number"
              onChange={(e) =>
                setNewProspectForm({
                  ...newProspectForm,
                  costPerSeat: e.target.value,
                })
              }
            />
          </div>

          <div
            style={{
              marginTop: 5,
            }}
          >
            <span>Total cost: </span>
            <span>
              {booking.seats} x {newProspectForm.costPerSeat || 0} ={" "}
              {(newProspectForm.costPerSeat || 0) * booking.seats}
            </span>
          </div>
          <div
            style={{
              marginTop: 5,
            }}
          >
            <p>Total Advance Payable for {booking.seats} seat(s)</p>
            <InputNumber
              value={newProspectForm.advance}
              onChange={(advance) =>
                setNewProspectForm({
                  ...newProspectForm,
                  advance,
                })
              }
            />
          </div>
          <div
            style={{
              marginTop: 5,
            }}
          >
            <p>Account manager</p>
            <Input
              value={newProspectForm.accountManager}
              onChange={(e) => {
                setNewProspectForm({
                  ...newProspectForm,
                  accountManager: e.target.value,
                });
              }}
            />
          </div>

          <div
            style={{
              marginTop: 5,
            }}
          >
            <p>Send email to client</p>
            <Radio.Group
              onChange={(e) => {
                setNewProspectForm({
                  ...newProspectForm,
                  sendEmail: e.target.value,
                });
              }}
              value={newProspectForm.sendEmail}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
        </div>
      );
    }

    if (newStatus === "client") {
      return (
        <div>
          <p>Enter the payment details of the client</p>
          <div>
            <p>Amount paid</p>
            <InputNumber
              value={newClientForm.amountPaid}
              onChange={(amountPaid) =>
                setNewClientForm({
                  ...newClientForm,
                  amountPaid,
                })
              }
            />
          </div>
          <div
            style={{
              marginTop: 5,
            }}
          >
            <p>Transaction ID</p>
            <Input
              value={newClientForm.transactionId}
              onChange={(e) =>
                setNewClientForm({
                  ...newClientForm,
                  transactionId: e.target.value,
                })
              }
            />
          </div>
          <div
            style={{
              marginTop: 5,
            }}
          >
            <p>Send transaction email</p>
            <Radio.Group
              onChange={(e) => {
                setNewClientForm({
                  ...newClientForm,
                  sendTransactionEmail: e.target.value,
                });
              }}
              value={newClientForm.sendTransactionEmail}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          <div
            style={{
              marginTop: 5,
            }}
          >
            <p>Send passenger email</p>
            <Radio.Group
              onChange={(e) => {
                setNewClientForm({
                  ...newClientForm,
                  sendPassengerEmail: e.target.value,
                });
              }}
              value={newClientForm.sendPassengerEmail}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
        </div>
      );
    }
  };

  const getConfirmationText = () => {
    if (newStatus === "closed") {
      return "Close Booking";
    }

    if (newStatus === "lead") {
      return "Convert to Lead";
    }

    if (newStatus === "prospect") {
      return "Convert to Prospect";
    }

    if (newStatus === "client") {
      return "Convert to Client";
    }
  };

  const onChange = (e) => {
    if (newStatus === "lead") {
      userContext.showLoading();
      api
        .moveToLead({ bookingId: booking._id })
        .then((res) => {
          const newBooking = res.booking;
          onClose(newBooking);
          userContext.showSuccess("Booking converted to lead");
        })
        .catch(() => {
          onClose();
          userContext.showError("Failed to convert booking to lead");
        })
        .finally(() => {
          userContext.hideLoading();
        });
    }

    if (newStatus === "closed") {
      if (
        (booking.status === "client" || booking.status === "prospect") &&
        !isBoolean(closeBookingForm.sendEmail)
      ) {
        userContext.showError("Please select email sending option");
        return;
      }

      userContext.showLoading();
      api
        .closeBooking({
          bookingId: booking._id,
          sendEmail: closeBookingForm.sendEmail,
        })
        .then((res) => {
          const newBooking = res.booking;
          onClose(newBooking);
          userContext.showSuccess("Booking closed");
        })
        .catch(() => {
          onClose();
          userContext.showError("Failed to close booking");
        })
        .finally(() => {
          userContext.hideLoading();
        });
    }

    if (newStatus === "prospect") {
      if (
        !newProspectForm.costPerSeat ||
        newProspectForm.costPerSeat < 0 ||
        !newProspectForm.advance ||
        newProspectForm.advance < 0 ||
        !newProspectForm.accountManager ||
        !isBoolean(newProspectForm.sendEmail)
      ) {
        userContext.showError("Please enter all fields");
        return;
      }

      userContext.showLoading();
      api
        .moveToProspect({
          bookingId: booking._id,
          advance: newProspectForm.advance,
          totalCost: newProspectForm.costPerSeat * booking.seats,
          accountManager: newProspectForm.accountManager,
          sendEmail: newProspectForm.sendEmail,
        })
        .then((res) => {
          const newBooking = res.booking;
          onClose(newBooking);
          userContext.showSuccess("Booking converted to prospect");
        })
        .catch(() => {
          onClose();
          userContext.showError("Failed to convert booking to prospect");
        })
        .finally(() => {
          userContext.hideLoading();
        });
    }

    if (newStatus === "client") {
      if (!isNumber(newClientForm.amountPaid) || newClientForm.amountPaid < 0) {
        userContext.showError("Please enter a valid amount");
        return;
      }

      if (!newClientForm.transactionId && newClientForm.amountPaid > 0) {
        userContext.showError("Please enter the transaction ID");
        return;
      }

      if (
        !isBoolean(newClientForm.sendTransactionEmail) ||
        !isBoolean(newClientForm.sendPassengerEmail)
      ) {
        userContext.showError("Please select all email sending options");
        return;
      }

      userContext.showLoading();
      api
        .moveToClient({ bookingId: booking._id, ...newClientForm })
        .then((res) => {
          const newBooking = res.booking;
          onClose(newBooking);
          userContext.showSuccess("Booking converted to client");
        })
        .catch(() => {
          onClose();
          userContext.showError("Failed to convert booking to client");
        })
        .finally(() => {
          userContext.hideLoading();
        });
    }
  };

  return (
    <Dialog
      open
      onClose={() => onClose()}
      style={{
        zIndex: 1029,
      }}
      fullWidth
    >
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent>
        <div>
          {getContent()}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button style={{ marginRight: 10 }} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type="primary" onClick={onChange}>
              {getConfirmationText()}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StatusChangeModal;
