import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useContext, useState } from "react";
import api from "../../api";
import { Button, Input } from "antd";
import { context } from "../../providers/user.provider";

const AddAgentToScheduleModal = ({ onClose }) => {
  const [agent, setAgent] = useState({
    name: "",
    email: "",
  });
  const userContext = useContext(context);

  const onAdd = () => {
    if (!agent.name || !agent.email) {
      userContext.showError("Please enter agent name and email");
      return;
    }

    if (
      new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(
        agent.email
      ) === false
    ) {
      userContext.showError("Please enter a valid email");
      return;
    }

    userContext.showLoading();

    api
      .createAgent({
        name: agent.name,
        email: agent.email,
      })
      .then(() => {
        userContext.showSuccess("Agent created successfully");
        onClose();
      })
      .catch(() => {
        userContext.showError("Error creating agent");
      })
      .finally(() => {
        userContext.hideLoading();
      });
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      style={{
        zIndex: 1029,
      }}
    >
      <DialogTitle>Add agent</DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <h4>Name</h4>
        <Input
          value={agent.name}
          type="text"
          onChange={(e) => setAgent({ ...agent, name: e.target.value })}
        />

        <h4>Email</h4>
        <Input
          value={agent.email}
          type="email"
          onChange={(e) => setAgent({ ...agent, email: e.target.value })}
        />

        <h5>
          An email will be sent to the agent to set their thumby aviation admin
          portal password
        </h5>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            type="secondary"
            onClick={onCancel}
            style={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={onAdd}>
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddAgentToScheduleModal;
