function formatDate(date) {
  const month =
    (date.getMonth() + 1).toString().length === 1
      ? "0" + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString();
  const day =
    date.getDate().toString().length === 1
      ? "0" + date.getDate().toString()
      : date.getDate().toString();
  const year = date.getFullYear().toString();
  return `${month}/${day}/${year}`;
}

export default formatDate;
