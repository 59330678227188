import React from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Select, Spin, Button, Tag, Badge, Input, Divider } from "antd";
import {
  CloseOutlined,
  MailOutlined,
  PhoneOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { message } from "antd";
const { TextArea } = Input;

const { Option } = Select;

message.config({
  duration: 1,
});

const CopyTextButton = ({ text }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        message.success(`${text} copied to clipboard`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        message.error("Failed to copy text");
      });
  };

  return (
    <Button
      onClick={handleCopy}
      icon={<CopyOutlined />}
      style={{ height: "16px", border: "none" }}
      type="ghost"
    ></Button>
  );
};

const CommentsModal = ({ lead, onClose, onAddComment }) => {
  const [comment, setComment] = React.useState("");

  if (!lead) {
    return null;
  }

  return (
    <Dialog open={!!lead} onClose={onClose}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div>Comments</div>
          </div>
          <div>
            <Button
              type="default"
              style={{ alignSelf: "flex-end", marginLeft: "5px" }}
              icon={<CloseOutlined />}
              onClick={onClose}
            ></Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ marginBottom: 0 }}>
              Travel Date: {moment(lead.travelDate).format("MMM DD YYYY")}
            </p>
            <p style={{ marginBottom: 0 }}>
              Schedule: {lead?.charterSchedule?.name}
            </p>
            <p style={{ marginBottom: 0 }}>
              <PhoneOutlined />{" "}
              <span style={{ marginLeft: "10px" }}>{lead?.mobileNumber}</span>
              <CopyTextButton text={lead?.mobileNumber} />
            </p>
            <p style={{ marginBottom: 0 }}>
              <MailOutlined />{" "}
              <span style={{ marginLeft: "10px" }}>{lead?.email}</span>
              <CopyTextButton text={lead?.email} />
            </p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ marginBottom: 0, marginLeft: "auto", color: "darkgray" }}>
            Lead created on: {moment(lead.createdAt).format("MMM DD YYYY")}
          </p>
        </div>

        <Divider />
        {lead?.comments?.length > 0 ? (
          <>
            {lead.comments.map((comment) => (
              <div style={{ marginTop: 10 }}>
                <div style={{ marginBottom: 5 }}>
                  <span style={{ color: "gray" }}>Admin</span>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: comment,
                  }}
                ></div>
              </div>
            ))}
          </>
        ) : (
          <p style={{ marginBottom: 10 }}>0 Comments</p>
        )}
        <Divider />

        <div style={{ display: "flex" }}>
          <TextArea
            placeholder="Add Comment"
            rows={3}
            onChange={({ target: { value } }) => setComment(value)}
            value={comment}
          />
          <Button
            type="primary"
            style={{ alignSelf: "flex-end", marginLeft: "5px" }}
            onClick={() => {
              onAddComment(lead._id, comment);
              setComment("");
            }}
          >
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CommentsModal;
