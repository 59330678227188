import { useContext, useMemo } from "react";
import { context } from "../providers/user.provider";

export const useRole = () => {
  const { user } = useContext(context);
  const role = useMemo(() => {
    return JSON.parse(user?.reloadUserInfo?.customAttributes || "{}");
  }, [user]);

  return role.admin ? "admin" : "agent";
};
