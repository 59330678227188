import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useCallback, useContext, useEffect, useState } from "react";
import api from "../../api";
import {
  Select,
  Spin,
  Button,
  Tag,
  Badge,
  Input,
  Divider,
  InputNumber,
} from "antd";
import { context } from "../../providers/user.provider";
import moment from "moment";
const { Option } = Select;

const AddAgentToScheduleModal = ({ onClose, schedule }) => {
  const [agents, setAgents] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [defaultSeats, setDefaultSeats] = useState(6);
  const userContext = useContext(context);

  const fetchAgents = useCallback(async () => {
    // Fetch agents here
    api.getAgents().then((response) => {
      setAgents(
        (response.agents || []).filter((agent) => !agent.isThumbyAgent)
      );
    });
  }, []);

  const onAdd = () => {
    userContext.showLoading();
    api
      .addAgentToSchedule({
        agentId: selectedAgentId,
        scheduleId: schedule._id,
        defaultSeats,
      })
      .then(() => {
        userContext.showSuccess("Agent added to package");
        onClose();
      })
      .catch(() => {
        userContext.showError("Error adding agent to package");
      })
      .finally(() => {
        userContext.hideLoading();
      });
  };
  const onCancel = () => {
    onClose();
  };

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return (
    <Dialog
      open
      onClose={onClose}
      style={{
        zIndex: 1029,
      }}
    >
      <DialogTitle>Add agent to {schedule.name}</DialogTitle>
      <DialogContent
        style={{
          width: "600px",
        }}
      >
        <div>
          from {moment(schedule.startDate).format("DD MMM YYYY")} to{" "}
          {moment(schedule.endDate).format("DD MMM YYYY")}
        </div>
        <Divider />
        <h4>Agent</h4>
        <Select
          labelInValue
          placeholder="Select Agent"
          style={{ width: 150 }}
          onChange={(agent) => setSelectedAgentId(agent.value)}
        >
          {agents.map((agent) => (
            <Option
              key={agent._id}
              value={agent._id}
              disabled={
                !!schedule.allowedAgents.find(
                  (allowedAgent) => allowedAgent._id === agent._id
                )
              }
            >
              {agent.name}
            </Option>
          ))}
        </Select>
        <h4 style={{ marginTop: "5px" }}>Default seats for this agent</h4>
        <InputNumber
          style={{ marginLeft: "5px" }}
          value={defaultSeats}
          onChange={setDefaultSeats}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            onClick={onCancel}
            style={{ marginRight: "5px" }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={onAdd} disabled={!selectedAgentId}>
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddAgentToScheduleModal;
